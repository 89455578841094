import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Tab, Table, Tabs, Badge } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import FaView from '../FaView';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from "../Picklist";
import Tran_commission_view from './Tran_commission_view';

function Tran_sb() {

    const sid = sessionStorage.getItem("sessionId");
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');
    const isdRef = useRef();

    const navigate = useNavigate();
    const [userRight, setUserRight] = useState([]);
    const [key, setKey] = useState("INFO");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [radioType, setRadioType] = useState("N");
    const [scrMode, setScrMode] = useState("");
    const [hdrSrno, setHdrSrno] = useState(0);

    const [sbLocation, setSBLocation] = useState(null);
    const [sbLocationValue, setSBLocationValue] = useState(0);
    const [sbDealer, setSBDealer] = useState(null);
    const [sbFromDate, setSBFromDate] = useState(new Date());
    const [sbToDate, setSBToDate] = useState(new Date());
    const [sbNewDate, setSBNewDate] = useState(new Date());
    const [sbSrnoFrom, setSBSrnoFrom] = useState("");
    const [sbSrnoTo, setSBSrnoTo] = useState("");
    const [sbStateValue, setSBStateValue] = useState(0);
    const [sbStateName, setSBStateName] = useState("");
    const [sbStateType, setSBStateType] = useState(0);
    const [disableOtherCharge, setDisableOtherCharge] = useState(true);

    const [productList, setProductList] = useState([]);
    const [pProduct, setPProduct] = useState("CN");
    const [pIsd, setPIsd] = useState(null);
    const [pDenomination, setPDenomination] = useState("");
    const [pQuantity, setPQuantity] = useState("");
    const [commission, setCommission] = useState("");
    const [isCommission, setIsCommission] = useState("0");
    const [commType, setCommType] = useState("");
    const [commValue, setCommValue] = useState("");
    const [pRate, setPRate] = useState("");
    const [pIsdId, setPIsdId] = useState(null);
    const [pAgent, setPAgent] = useState(null);
    const [counter, setCounter] = useState(0);
    const [productLineNo, setProductLineNo] = useState(0);
    const [maxPRate, setMaxPRate] = useState(0);
    const [minPRate, setMinPRate] = useState(0);

    const [pdAccount, setPdAccount] = useState(null);
    const [pdPaymentType, setPdPaymentType] = useState("");
    const [pdAmount, setPdAmount] = useState("");
    const [pdChequeNum, setPdChequeNum] = useState("");
    const [paymentData, setPaymentData] = useState([]);
    const [chequeBankName, setChequeBankName] = useState("");
    const [chequeDate, setChequeDate] = useState(null);
    const [ldgSrchType, setLdgSrchType] = useState("");
    const [paymentLineNo, setPaymentLineNo] = useState(0);

    const [pOtherCharges, setPOtherCharges] = useState(0);
    const [pTotalAmount, setPTotalAmount] = useState(0);
    const [pCgst, setPCgst] = useState(0);
    const [pSgst, setPSgst] = useState(0);
    const [pIgst, setPIgst] = useState(0);
    const [pUgst, setPUgst] = useState(0);
    const [pForexTotal, setPForexTotal] = useState(0);
    const [pRoundOff, setPRoundOff] = useState(0);
    const [pTaxableVal, setPTaxableVal] = useState(0);

    const [nDealer, setNDealer] = useState(null);
    const [nState, setNState] = useState({ value: 0, label: "Select" });
    const [nMode, setNMode] = useState("B");
    const [nExecutive, setNExecutive] = useState(null);
    const [nTranType, setNTranType] = useState("AF");
    const [randomString, setRandomString] = useState("");

    const [sbList, setSBList] = useState([]);
    const [hdrDate, setHdrDate] = useState(null);
    const [hdrLocation, setHdrLocation] = useState("");


    const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });
    const [showFaView, setShowFaView] = useState(false);
    const [usdRate, setUsdRate] = useState(0);
    const [tranAuditTrail, setTranAuditTrail] = useState([]);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);

    const [showCommissionDetail, setShowCommissionDetail] = useState(false);


    useEffect(() => {

        Common.getActiveBranch().then(response => {
            setSBLocation(response);
            response != null && handleLocationChange(response);
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_SB'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
        // Common.callApi(Common.apiIsd, [sid, "ddlistjsonselect", 'CN'], result => {
        //   setIsdOptions(JSON.parse(result));
        // });
        // Common.callApi(Common.apiResidentialStatus, [sid, "listResidential"], result => {
        //   setResidentOptions(JSON.parse(result));
        // });
        // Common.callApi(Common.apiState, [sid, "getState", 1], result => {
        //   setStateOptions(JSON.parse(result));
        // });
    }, []);


    const handleLocationChange = (value) => {
        setSBLocation(value);
        var id = value.id;
        var location = id.split("^");
        setSBLocationValue(location[1]);
        setSBStateValue(location[3] == null ? 0 : location[3]);
        setSBStateType(location[4]);
        setSBStateName(location[5] === "" ? "Select" : location[5]);

        setHdrLocation(location[2]);
        sessionStorage.activeBranch = location[1];
    }

    const handleDealerChange = (value) => {
        setSBDealer(value);
    }

    const handleExecutiveChange = (value) => {
        setNExecutive(value);
    }

    const otherChargeChange = (v) => {
        const obj = {
            tranType: "SB",
            type: "SALE",
            nState: nState.value,
            pOtherCharges: v === "" ? 0 : v,
            ppStateType: sbStateType,
            ppStateValue: sbStateValue
        }
        calcInvoice(productList, obj);
        Common.validateDecValue(v, setPOtherCharges);
    }

    const listSBdata = () => {
        var msg = [], i = 0;
        $(".loader").show();

        if (sbLocation === null || (radioType === 'S' && !sbSrnoFrom) || (radioType === 'S' && !sbSrnoTo) || (radioType === 'D' && !sbFromDate) ||
            (radioType === 'D' && !sbToDate) || (radioType === 'I' && sbDealer === null) || (radioType === 'I' && !sbFromDate) || (radioType === 'I' && !sbToDate)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (sbLocation === null ? " Location." : '');
            msg[i++] = (radioType === 'S' && !sbSrnoFrom ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !sbSrnoTo ? " To Srno." : '');
            msg[i++] = (radioType === 'D' && !sbFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !sbToDate ? " To Date." : '');
            msg[i++] = (radioType === 'I' && sbDealer === null ? " Dealer." : '');
            msg[i++] = (radioType === 'I' && !sbFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'I' && !sbToDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                type: "SB",
                radioType: radioType,
                sbLocationValue: sbLocationValue,
                // ppCorporate: ppCorporate,
                sbDealer: sbDealer,
                sbNewDate: Common.dateYMD(sbNewDate),
                sbSrnoTo: sbSrnoTo,
                sbSrnoFrom: sbSrnoFrom,
                sbFromDate: Common.dateYMD(sbFromDate),
                sbToDate: Common.dateYMD(sbToDate)
            }
            Common.callApi(Common.apiTranSB, [sid, "sblist", JSON.stringify(obj)], result => {
                console.log(result);
                $(".loader").hide();
                setSBList(JSON.parse(result));
            });
        }
    }

    const addNewForm = () => {
        var d = Common.dateYMD(sbNewDate);
        var ddmy = Common.dateDMY(sbNewDate);
        var today = Common.dateYMD(new Date());
        if (sbLocation === null) {
            setMsgBox(true);
            setMsgText("Please select location");
        } else if (d > finEndDate || d < finStartDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else if (d > today) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0011"));
        } else {
            setHdrDate(ddmy);

            setScrMode("A");
            setKey("INFO");
            setHdrSrno(0);
            setNDealer(null);
            setNExecutive(null);
            if (sbStateValue * 1 > 0 && sbStateValue != null) setNState({ value: sbStateValue, label: sbStateName });
            else setNState({ value: 0, label: "Select" });

            setPProduct("CN");
            setPIsd(null);
            setPDenomination("");
            setPQuantity("");
            setPRate("");
            setCommType("");
            setCommValue("");
            setCommission("");
            setIsCommission("0");
            setPForexTotal(0);
            setPTotalAmount(0);
            setPCgst(0);
            setPIgst(0);
            setPUgst(0);
            setPSgst(0);
            setPRoundOff(0);
            setPOtherCharges(0);
            setPTaxableVal(0);

            setProductList([]);
            setPaymentData([]);
            setProductLineNo(0);
            setPaymentLineNo(0);
        }
    }

    const handleNewDealerChange = (value) => {
        setNDealer(value);
    }

    const handleTabChange = (k) => {
        setKey(k);
        if (k === "PAYMENT") {
            const amt = paymentData.reduce((total, payment) => total + payment.tp_amount * 1, 0);
            setPdAmount(1 * pTotalAmount - amt);
            // const obj = {
            //     nCorporate: nCorporate
            // }
            // Common.callApi(Common.apiTranPP, [sid, "getcorporate", JSON.stringify(obj)], result => {
            //     setPdAccountOptions(JSON.parse(result));
            // });
        }
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN(Common.getMessage("WRN0000"));
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    const editData = () => {
        setScrMode("E");
        if (productList.length > 0) {
            setDisableOtherCharge(false);
        }
    }

    const handleCommissionCalc = (v) => {
        Common.validateDecValue(v, setCommission);
        setCommValue(calcCommission(v * 1, commType, pQuantity, pDenomination, pRate));
    }

    const commissionTypeChange = (v) => {
        setCommType(v);
        setCommission("");
        setCommValue("");
    }

    const handleAgentChange = (value) => {
        setPAgent(value);
        Common.callApi(Common.apiLedger, [sid, "getisdcommission", value.id, pIsdId, pProduct], result => {
            console.log(result);
            let resp = JSON.parse(result);
            setCommType(resp.type);
            setCommission(resp.value);
            var commvalue;
            if (resp.type === "P" || resp.type === "R") {
                commvalue = calcCommission(resp.value, resp.type, pQuantity, pDenomination, pRate);
            } else {
                commvalue = resp.value;
            }
            setCommValue(commvalue);
        });
    }

    const handleChangeIsd = (value) => {
        setPIsd(value);
        let a = value.id.split("^");
        setPIsdId(a[1]);
        setPAgent(null);
        setCommType("");
        setCommValue("");
        const obj = {
            isd: a[1],
            location: sbLocationValue,
            product: pProduct
        }
        Common.callApi(Common.apiMasterIBR, [sid, "getrate", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPRate(resp.salerate * 1);
            setMaxPRate(resp.maxsalerate * 1);
            setMinPRate(resp.minsalerate * 1);
            setUsdRate(resp.usdratesale * 1);
        });
    }

    const handleRateChange = (v) => {
        Common.validateDecValue(v, setPRate);
        setCommValue(calcCommission(commission, commType, pQuantity, pDenomination, v));
    }

    const handleDenoChange = (v) => {
        Common.validateNumValue(v, setPDenomination);
        setCommValue(calcCommission(commission, commType, pQuantity, v, pRate));
    }

    const handleQuantityChange = (v) => {
        Common.validateNumValue(v, setPQuantity);
        setCommValue(calcCommission(commission, commType, v, pDenomination, pRate));
    }

    const calcCommission = (value, type, quantity, deno, rate) => {
        var comm = 0;
        if (type === "P") {
            comm = (value * quantity * deno) / 100;
        } else if (type === "R") {
            comm = (quantity * deno * rate) * (value / 100);
        } else {
            comm = value;
        }
        return comm.toFixed(2);
    }

    const handlePaymentType = (v) => {
        setPdPaymentType(v);
        setPdAccount(null);
        setPdChequeNum("");
        var ldgType = "";
        if (v === "") return;
        if (v === "CH")
            ldgType = "CASH";
        else if (v === "BN") {
            ldgType = "BANK";
            setLdgSrchType("BANK");
            return;
        } else if (v === "CQ")
            ldgType = "CHEQUE";
        else
            ldgType = "WALKIN";

        setLdgSrchType(ldgType);
        Common.callApi(Common.apiLedger, [sid, "getledgerinfo", ldgType], result => {
            let resp = JSON.parse(result);
            setPdAccount({ id: resp.id, label: resp.label });
        });
    }

    const handleAccountValue = (value) => {
        setPdAccount(value);
        var id = value.id.split("^");
    }

    const calcInvoice = (productlist, obj) => {
        Common.callApi(Common.apiCalcTaxable, [sid, "calcinvoice", JSON.stringify(productlist), JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPForexTotal(resp.fxamt);
            setPSgst(resp.sgst);
            setPTotalAmount(resp.totalamt);
            setPTaxableVal(resp.taxablevalue);
            setPRoundOff(resp.roundoff);
            setPCgst(resp.cgst);
            setPIgst(resp.igst);
            setPUgst(resp.ugst);
            var amt = 0;
            for (var i = 0; i < paymentData.length; i++) {
                amt += paymentData[i].tp_amount * 1;
            }
            setPdAmount(1 * resp.totalamt - amt);
        });
    }

    const addProduct = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if (pIsd == null || pDenomination === "" || pQuantity === "" || pRate === ""
            ||
            (isCommission === "1" && (commType === "" || pAgent === null || commValue === ""))
        ) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pIsd == null ? "Please select Isd." : '');
            msg[i++] = (pDenomination === '' ? "Please fill Denomination." : '');
            msg[i++] = (pQuantity == '' ? "Please fill Quantity." : '');
            msg[i++] = (pRate == '' ? "Please fill Rate." : '');
            if (isCommission === "1" && (commType === "" || pAgent === null || commValue === "")) {
                msg[i++] = (pAgent == null ? "Agent." : '');
                msg[i++] = (commType == '' ? "Commission type." : '');
                msg[i++] = (commValue == '' ? "Commission value." : '');
            }
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (maxPRate != 0 && minPRate != 0 && (pRate > maxPRate || pRate < minPRate)) {
            $(".loader").hide();
            msg[i++] = (pRate > maxPRate ? "Maximum rate is " + maxPRate : '');
            msg[i++] = (pRate < minPRate ? "Maximum rate is " + minPRate : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            $(".loader").hide();
            var isd = pIsd.id.split("^");
            var agentgrp = 0;
            var agentaccnt = 0;
            var agentname = "";
            var issgrp = 0;
            var issaccnt = 0;
            if (pAgent !== null) {
                var agent = pAgent.id.split("^");
                agentgrp = agent[1];
                agentaccnt = agent[2];
                agentname = agent[5];
            }
            var usdAmt = 0;
            if (usdRate != "" && usdRate != 0) {
                usdAmt = (pRate * pQuantity * pDenomination) / usdRate;
            }
            const obj = {
                tranType: "SB",
                type: "SALE",
                uniquekey: Common.getRandomString(15),
                prd_line_no: 1 * productLineNo + 1,
                prd_location: sbLocationValue,
                prd_type: 'SB',
                prd_srno: hdrSrno,
                prd_isd: isd[1],
                prd_date: Common.dateYMD(sbNewDate),
                prd_product: pProduct,
                prd_deno: pDenomination,
                prd_qty: pQuantity,
                prd_rate: pRate,
                prd_amount: pRate * pQuantity * pDenomination,
                // prd_from: prFrom,
                // prd_prefix: prPrefix,
                // prd_to: prTo,
                prd_group: issgrp,
                prd_account: issaccnt,
                prd_agent_group: agentgrp,
                prd_agent_ledger: agentaccnt,
                // prd_card_no: pVtmCardNo,
                isd_name: isd[2],
                randomString: randomString,
                prd_commission_type: commType,
                prd_commission_value: commValue == "" ? 0 : commValue,
                prd_commission: commission == "" ? 0 : commission,
                //nCorporate: nCorporate,
                nState: nState.value,
                pOtherCharges: pOtherCharges === "" ? 0 : pOtherCharges,
                ppStateType: sbStateType,
                ppStateValue: sbStateValue,
                scrMode: scrMode,
                prd_usd_rate: usdRate == "" ? 0 : usdRate.toFixed(2),
                prd_usd_amount: usdAmt.toFixed(2),
                prd_agent_name: agentname,
                isCommission: isCommission,
                delny: "Y"
            }
            var productlist = Common.arrayAddItem(productList, obj);
            if (productlist.length > 0) {
                setDisableOtherCharge(false);
            }
            const obj2 = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                // TLine: productLineNo + 1,
                TLoc: sbLocationValue,
                TType: 'SB',
                // TNum?
                Product: pProduct,
                ISD: isd[2],
                Deno: pDenomination,
                Quantity: pQuantity,
                Rate: pRate,
                Amount: pRate * pQuantity * pDenomination,
                // ...(pProduct === "ETC" ? {
                // ProductFrom: prFrom,
                // ProductTo: prTo,
                // ProductPrefix: prPrefix,
                ProductGroup: issgrp,
                ProductAccout: issaccnt,
                // } : {}),
                CommsnType: commType,
                AgentGroupCode: agentgrp,
                AgentLedgerCode: agentaccnt,
                CommsnRate: commValue == "" ? 0 : commValue,
                CommsnAmount: commission == "" ? 0 : commission,

            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
            }
            setProductList(productlist);
            setCounter(counter + 1);
            setProductLineNo(productLineNo + 1);
            setPIsd(null);
            setPDenomination("");
            setPQuantity("");
            setPRate("");
            // setPIssuer(null);
            // setPrPrefix("");
            // setPrFrom("");
            // setPrTo("");
            // setPVtmCardNo("");
            setCommValue("");
            setCommission("");
            setCommType();
            setPAgent(null);
            setCommType("");
            calcInvoice(productlist, obj);
        }
    }

    const addPayment = () => {
        var msg = [], i = 0;
        if (pTotalAmount < 1) {
            msg[i++] = (pTotalAmount < 1 ? "Total amount must be greater than 0." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pdPaymentType === "" || pdAccount === null || pdAmount < 1
            || (pdPaymentType === 'CQ' && (pdChequeNum === "" || chequeBankName === "" || chequeDate === null))
            || (pdPaymentType === "BN" && pdChequeNum === "")
        ) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pdAccount === null ? "Account." : '');
            msg[i++] = (pdPaymentType === "" ? "Payment Type." : '');
            msg[i++] = (pdAmount < 1 ? "Entered amount can't be 0." : '');
            if (pdPaymentType === 'CQ' && (pdChequeNum === "" || chequeBankName === "" || chequeDate === null)) {
                msg[i++] = (pdChequeNum === "" ? "Cheque Number." : '');
                msg[i++] = (chequeBankName === "" ? "Bank Name." : '');
                msg[i++] = (chequeDate === null ? "Cheque Date" : '');
            }
            if (pdPaymentType === "BN" && pdChequeNum === "") msg[i++] = (pdChequeNum === "" ? "Reference Number." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            var grp = 0;
            var accnt = 0;
            var accntname = '';
            if (pdAccount !== null) {
                var a = pdAccount.id.split("^");
                grp = a[1];
                accnt = a[2];
                accntname = a[5];
            }
            const obj = {
                uniquekey: Common.getRandomString(10),
                tp_line_no: paymentLineNo + 1,
                tp_location: sbLocationValue,
                tp_srno: hdrSrno,
                tp_payment_type: pdPaymentType,
                tp_account: accnt,
                tp_group: grp,
                mvli_ledger_name: accntname,
                tp_amount: pdAmount,
                tp_cheque: pdChequeNum,
                tp_cheque_bank: chequeBankName,
                tp_cheque_date: pdPaymentType === "CQ" ? Common.dateYMD(chequeDate) : "NULL",
                tp_type: "SP",
                delny: 'Y'
            }
            const paymentdata = Common.arrayAddItem(paymentData, obj);
            const obj2 = {
                TabType: "Payment",
                AType: "A",
                ALineNo: tranAuditTrail.length + 1,
                // TLine: paymentLineNo + 1,
                TLoc: sbLocationValue,
                TType: 'SB',
                PaymentType: pdPaymentType,
                PaymentLedgerCode: accnt,
                PaymentGroupCode: grp,
                PaymentAccountName: accntname,
                PaymentAmount: pdAmount,
                // ...(pdPaymentType == "BN" ? {
                ReferenceNo: pdChequeNum
                // } : {}),

            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
            }
            setPaymentData(paymentdata);
            setPdPaymentType("");
            setPaymentLineNo((1 * paymentLineNo) + 1);
            setPdAmount("");
            setPdAccount(null);
            setPdChequeNum("");
            setChequeBankName("");
            setChequeDate(null);
            setCounter(counter + 1);
            const amt = paymentdata.reduce((total, payment) => total + payment.tp_amount * 1, 0);
            setPdAmount(1 * pTotalAmount - amt);
        }
    }

    const deleteProduct = (value) => {
        const itemToDelete = productList.find(item => item.uniquekey === value);

        if (itemToDelete) {
            const formattedItem = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                TLoc: sbLocationValue,
                TType: 'SB',
                Product: itemToDelete.prd_product,
                ISD: itemToDelete.isd_name,
                Deno: itemToDelete.prd_deno,
                Quantity: itemToDelete.prd_qty,
                Rate: itemToDelete.prd_rate,
                Amount: itemToDelete.prd_amount,
                ProductFrom: itemToDelete.prd_from,
                ProductTo: itemToDelete.prd_to,
                ProductPrefix: itemToDelete.prd_prefix,
                ProductGroup: itemToDelete.prd_group,
                ProductAccout: itemToDelete.prd_account,
                AgentGroupCode: itemToDelete.prd_agent_group,
                AgentLedgerCode: itemToDelete.prd_agent_ledger,
                CommsnType: itemToDelete.prd_commission_type,
                CommsnRate: itemToDelete.prd_commission_value,
                CommsnAmnt: itemToDelete.prd_commission,
            }
            console.log(formattedItem);
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }
        }
        const obj = {
            tranType: "SB",
            type: "SALE",
            nState: nState.value,
            pOtherCharges: pOtherCharges === "" ? 0 : pOtherCharges,
            ppStateType: sbStateType,
            ppStateValue: sbStateValue
        }
        const productlist = Common.arrayRemoveItem(productList, "uniquekey", value);
        setProductList(productlist);
        calcInvoice(productlist, obj);
        setCounter(counter - 1);
    }

    const deletePayment = (value, delny) => {
        if (delny === "N") {
            setMsgBox(true);
            setMsgText("This cheque has already used.");
        } else {
            const itemToDelete = paymentData.find(item => item.uniquekey === value);

            if (itemToDelete) {
                const formattedItem = {
                    TabType: "Payment",
                    ALineNo: tranAuditTrail.length + 1,
                    AType: "D",
                    TLine: paymentLineNo + 1,
                    TLoc: sbLocationValue,
                    TType: 'SB',
                    PaymentType: itemToDelete.tp_payment_type,
                    PaymentGroupCode: itemToDelete.tp_group,
                    PaymentLedgerCode: itemToDelete.tp_account,
                    PaymentAccountName: itemToDelete.mvli_ledger_name,
                    PaymentAmount: itemToDelete.tp_amount,
                    ReferenceNo: itemToDelete.tp_cheque,
                };
                if (userRight.edit === "1" && scrMode === 'E') {
                    setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
                }

            }
            const paymentdata = Common.arrayRemoveItem(paymentData, "uniquekey", value);
            setPaymentData(paymentdata);

            const amt = paymentData.reduce((total, payment) => total + payment.tp_amount * 1, 0);
            setPdAmount(1 * pTotalAmount - amt);
        }
        setCounter(counter - 1);
    }

    const saveData = () => {
        $(".loader").show();
        var msg = [], i = 0;
        var totalinr = 0;
        for (var i = 0; i < paymentData.length; i++) {
            if (paymentData[i].tp_payment_type === "PB")
                totalinr -= paymentData[i].tp_amount * 1;
            else totalinr += paymentData[i].tp_amount * 1;
        }
        if (nDealer === null) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (nDealer === null ? "Dealer" : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pTotalAmount != totalinr) {
            $(".loader").hide();
            setMsgText("Total amount of payment must be equal to calculated INR.");
            setMsgBox(true);
        } else {
            $(".loader").hide();
            const obj = {
                type: 'SB',
                srno: hdrSrno,
                sbLocationValue: sbLocationValue,
                //spSaleType: spSaleType,
                sbNewDate: Common.dateYMD(sbNewDate),
                //nCorporate: nCorporate,
                nDealer: nDealer,
                nTranType: nTranType,
                // nCountry: nCountry,
                nState: nState.value,
                //nResident: nResident,
                //nTraveller: nTraveller,
                //nPurpose: nPurpose,
                nExecutive: nExecutive,
                pForexTotal: pForexTotal,
                //nFlightNo: nFlightNo,
                //nTicketNo: nTicketNo,
                //nVisaType: nVisaType,
                //nDepDate: Common.dateYMD(nDepDate),
                //nTicketClass: nTicketClass,
                //nDuration: nDuration,
                //nPanForm60: nPanForm60,
                nMode: nMode,
                pSgst: pSgst,
                pIgst: pIgst,
                pUgst: pUgst,
                pCgst: pCgst,
                pOtherCharges: pOtherCharges,
                pRoundOff: pRoundOff,
                pTaxableVal: pTaxableVal,
                pTotalAmount: pTotalAmount,
                //nTravellerCode: nTravellerCode,
                //randomString: randomString,
                sbProductList: productList,
                paymentData: paymentData,
                radioType: radioType,
                sbSrnoTo: sbSrnoTo,
                sbSrnoFrom: sbSrnoFrom,
                sbFromDate: Common.dateYMD(sbFromDate),
                sbToDate: Common.dateYMD(sbToDate)
            }
            Common.callApi(Common.apiTranSB, [sid, "savedata", JSON.stringify(obj), JSON.stringify(tranAuditTrail)], result => {
                let resp = JSON.parse(result);
                if (resp.msg === "MSG0000") {
                    $(".loader").hide();
                    setScrMode("");
                    setSBList(resp.data);
                    setMsgBox(true);
                    setMsgText(Common.getMessage(resp.msg));
                    setTranAuditTrail([]);
                } else if (resp.msg === "ERR0000") {
                    $(".loader").hide();
                    setMsgBox(true);
                    setMsgText(Common.getMessage("ERR0000"));
                } else {
                    $(".loader").hide();
                    setMsgBox(true);
                    setMsgText(resp.msg);
                }
            })
        }
    }

    const fillViewForm = (hdrsrno, location, type) => {
        setHdrSrno(hdrsrno);
        setKey('INFO');
        //setRandomString("0");
        $(".loader").show();
        const obj = {
            srno: hdrsrno,
            sbLocationValue: location,
            type: type
        }
        Common.callApi(Common.apiTranSB, [sid, "viewdata", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                $(".loader").hide();
                setScrMode("Q");
                if (resp.executive == "^0^") setNExecutive(null);
                else setNExecutive({ id: resp.executive, label: resp.executive_name });
                setNMode(resp.mode);
                //setNPurpose({ id: resp.purpose, label: resp.purpose_name });
                if (resp.dealerid === "^0^0^") setNDealer(null);
                else setNDealer({ id: resp.dealerid, label: resp.dealerlabel });

                setPForexTotal(resp.forextotal);
                setPCgst(resp.cgst);
                setPSgst(resp.sgst);
                setPIgst(resp.igst);
                setPUgst(resp.ugst);
                setPRoundOff(resp.round_off);
                setPTotalAmount(resp.total_inr);
                setPOtherCharges(resp.other_charge);
                setPTaxableVal(resp.taxablevalue);

                setProductList(resp.productlist);
                setPaymentData(resp.paydetails);
                setPaymentLineNo(resp.paymaxlineno);
                setProductLineNo(resp.maxlineno);

                setHdrDate(resp.date);
                setHdrLocation(resp.locname);
            }
        });
    }

    const switchToPaymentTab = (e) => {
        if (e.key === 'Enter') setKey("PAYMENT");
        else if (e.key === "Tab") {
            e.preventDefault();
            isdRef.current.focus();
        }
    }


    const handleFaView = () => {
        setShowFaView(true);
        setFaViewObject({ location: sbLocationValue, trannumber: hdrSrno, type: 'SB' });
    }
    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "SB", trannumber: hdrSrno, trancategory: "T", location: sbLocationValue });
    }

    const handleShowCommissionDetail = () => {
        setShowCommissionDetail(true);
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Sale Bulk</h2>
                    </Col>
                </Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={sbLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleLocationChange}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col style={{ display: "flex" }}>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                                <Form.Check checked={radioType === "I"} onChange={() => setRadioType("I")} type='radio' label="Dealer" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === "N" ?
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Date</Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={sbNewDate}
                                                    onChange={(date) => setSBNewDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        : radioType === "S" ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control type='text' maxLength={5} value={sbSrnoFrom} onChange={e => Common.validateNumValue(e.target.value, setSBSrnoFrom)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control type='text' maxLength={5} value={sbSrnoTo} onChange={e => Common.validateNumValue(e.target.value, setSBSrnoTo)} />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : radioType === "D" || radioType === "I" ?
                                                <>
                                                    {
                                                        radioType === "I" ?
                                                            <Col className="col-md-6 col-12">
                                                                <Form.Group>
                                                                    <Form.Label>Dealer<span className="colorThemeRed">*</span></Form.Label>
                                                                    <AsyncSelect
                                                                        cacheOptions={false}
                                                                        defaultOptions={false}
                                                                        value={sbDealer}
                                                                        getOptionLabel={e => e.label + ' '}
                                                                        getOptionValue={e => e.id}
                                                                        loadOptions={(search) => Picklist.fetchDealerTypePicklist(search, sbLocationValue, "2,3,4")}
                                                                        onInputChange={Picklist.handleInputChange}
                                                                        onChange={handleDealerChange}
                                                                    >
                                                                    </AsyncSelect>
                                                                </Form.Group>
                                                            </Col>
                                                            : null
                                                    }
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={sbFromDate}
                                                                onChange={(date) => setSBFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={sbToDate}
                                                                onChange={(date) => setSBToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                : null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.query === "1" && radioType !== "N" ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => listSBdata()}>List</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.add === "1" && radioType === "N" ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNewForm()}>Add New</Button> : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Table responsive striped>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Sr No</th>
                                            <th>Type</th>
                                            <th>Location</th>
                                            <th>Dealer</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sbList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span onClick={() => fillViewForm(item.hdr_srno, item.hdr_location, item.hdr_type)} className='pointer colorThemeBlue'>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </span>
                                                    </td>
                                                    <td>{item.hdr_srno}</td>
                                                    <td>{item.hdr_type}</td>
                                                    <td>{item.entity_name}</td>
                                                    <td>{item.mvli_ledger_name}</td>
                                                    <td>{item.hdr_date}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </>
                        :
                        <>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <p>{randomString}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => cancelConfirm()}>Back</Button>
                                    &nbsp;
                                    {
                                        (userRight.edit === "1" && scrMode === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                editData()}>Edit</Button>&nbsp;
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    {/* {
                                        (userRight.query === "1") ? <>
                                            <Button variant='outline-success' size='sm' className='buttonStyle' onClick={() => print("D")}>With Deno</Button>&nbsp;
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => print("")}>Without Deno</Button>
                                        </> : null
                                    } */}
                                    {
                                        (userRight.query === "1" && scrMode === "Q") ?
                                            <>&nbsp;
                                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => handleFaView()}>Fa View</Button>
                                                {(userRight.edit === "1" && scrMode === 'Q') ? <>
                                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                        btnActivityLog()}>Activity Log</Button>&nbsp;
                                                </> : null
                                                }
                                            </> : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className="col-md-2 col-4">
                                    <span>Srno : <b>{hdrSrno} </b></span>
                                </Col>
                                <Col className="col-md-2 col-4">
                                    <span>Location : <b>{hdrLocation} </b></span>
                                </Col>
                                <Col className="col-md-2 col-4">
                                    <span>Date : <b>{hdrDate}</b></span>
                                </Col>
                                <Col className='ppCharges'>
                                    <span><b>Forex Total (INR): {pForexTotal}</b></span>
                                    &nbsp;&nbsp;&nbsp;
                                    <span><b>Total Amount (INR): {pTotalAmount}</b></span>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Tabs activeKey={key} defaultActiveKey="INFO" onSelect={k => handleTabChange(k)}>
                                <Tab eventKey="INFO" title="Info">
                                    <Row>
                                        <Col><h4>Transaction Info</h4></Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-md-3 col-6">
                                            <Form.Group>
                                                <Form.Label>Executive</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nExecutive}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={Picklist.fetchExecutivePicklist}
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleExecutiveChange}
                                                    isDisabled={scrMode === "Q"}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-md-3 col-6">
                                            <Form.Group>
                                                <Form.Label>Mode<span className="colorThemeRed">*</span></Form.Label>
                                                <Form.Select disabled={scrMode === "Q"} value={nMode} onChange={e => setNMode(e.target.value)}>
                                                    <option value="B">In Branch</option>
                                                    <option value="D">Delivery</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col><h4>Dealer Info</h4></Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-md-9 col-12">
                                            <Form.Group>
                                                <Form.Label>Dealer<span className="colorThemeRed">*</span>
                                                </Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nDealer}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchDealerTypePicklist(search, sbLocationValue, "2,3,4")}
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleNewDealerChange}
                                                    isDisabled={scrMode === "Q"}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Transaction Type</Form.Label>
                                                <Form.Select disabled={scrMode === "Q"} value={nTranType} onChange={e => setNTranType(e.target.value)}>
                                                    <option value="AF">ADS and FFMC</option>
                                                    <option value="DB">DESPATCH ABROAD</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6 mt-2'>
                                            <Button variant='outline-secondary' size='sm' onClick={() => setKey("DETAILS")}>Details-&gt;</Button>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="DETAILS" title="Details">
                                    <Row>
                                        <Col>
                                            <h4>Product Info</h4>
                                        </Col>
                                    </Row>
                                    {
                                        scrMode === "Q" ? null
                                            :
                                            <>
                                                <Row>
                                                    <Col className='col-md-3 col-6' style={{ display: "none" }}>
                                                        <Form.Group>
                                                            <Form.Label>Product<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Select value={pProduct} onChange={e => setPProduct(e.target.value)}>
                                                                <option value="CN">CN</option>
                                                                <option value="VTM">VTM</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    < Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                ref={isdRef}
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={pIsd}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, pProduct)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleChangeIsd}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Denomination
                                                                <span className="colorThemeRed">*</span>
                                                            </Form.Label>
                                                            <Form.Control placeholder='Denomination' type='text' value={pDenomination} maxLength={5}
                                                                onChange={e => handleDenoChange(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Quantity<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control type='text' placeholder='Quantity' value={pQuantity} maxLength={9}
                                                                onChange={e => handleQuantityChange(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Rate<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control type='text' placeholder='Rate' value={pRate} maxLength={5}
                                                                onChange={e => handleRateChange(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Commission</Form.Label>
                                                            <Form.Select value={isCommission} onChange={e => setIsCommission(e.target.value)}>
                                                                <option value="0">No</option>
                                                                <option value="1">Yes</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        isCommission === "1" ?
                                                            <>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Agent<span className="colorThemeRed">*</span></Form.Label>
                                                                        <AsyncSelect
                                                                            cacheOptions={false}
                                                                            defaultOptions={false}
                                                                            value={pAgent}
                                                                            getOptionLabel={e => e.label + ' '}
                                                                            getOptionValue={e => e.id}
                                                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'AGENT', sbLocationValue)} // location = 1 , 2
                                                                            onInputChange={Picklist.handleInputChange}
                                                                            onChange={handleAgentChange}
                                                                        >
                                                                        </AsyncSelect>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className="col-md-3 col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Select value={commType} onChange={e => commissionTypeChange(e.target.value)}>
                                                                            <option value="">Select</option>
                                                                            <option value="P">Paisa</option>
                                                                            <option value="R">Percentage</option>
                                                                            <option value="F">Flat</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label>Commission<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Control type='text' maxLength={5} value={commission} onChange={e => handleCommissionCalc(e.target.value)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label>Commission Value</Form.Label>
                                                                        <Form.Control disabled value={commValue} onChange={e => Common.validateDecValue(e.target.value, setCommValue)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            : null
                                                    }
                                                </Row>
                                            </>
                                    }
                                    <Row>
                                        <Col className="col-md-3 col-6">
                                            <Badge className='handCursor' bg="primary" onClick={handleShowCommissionDetail}>View Commission Details</Badge>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addProduct()}>Add</Button>
                                            &nbsp;
                                            <Button variant='outline-secondary' size='sm' onClick={() => setKey("PAYMENT")} onKeyDown={switchToPaymentTab}>Payment -&gt;</Button>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Table responsive bordered striped>
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Isd Code</th>
                                                    <th>Isd Name</th>
                                                    <th>Denomination</th>
                                                    <th>Quantity</th>
                                                    <th>Rate</th>
                                                    <th>Amount</th>
                                                    <th>USD Rate</th>
                                                    <th>USD Equivalent Amount</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    productList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.prd_product}</td>
                                                            <td>{item.prd_isd}</td>
                                                            <td>{item.isd_name}</td>
                                                            <td>{item.prd_deno}</td>
                                                            <td>{item.prd_qty}</td>
                                                            <td>{item.prd_rate}</td>
                                                            <td>{item.prd_amount}</td>
                                                            <td>{item.prd_usd_rate}</td>
                                                            <td>{item.prd_usd_amount}</td>
                                                            {/* <td>{item.prd_prefix}</td>
                                                            <td>{item.prd_from}</td>
                                                            <td>{item.prd_to}</td> */}
                                                            <td>
                                                                {
                                                                    scrMode === "Q" || item.delny === "N" ? null :
                                                                        <span onClick={() => deleteProduct(item.uniquekey)} className='colorThemeBlue pointer'>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>
                                <Tab eventKey="PAYMENT" title="Payment">
                                    {/* <Row>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>Taxable Value: {pTaxableVal}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>SGST: {pSgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>IGST: {pIgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>CGST : {pCgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>UGST : {pUgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>Round Off: {pRoundOff}</Form.Label>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col className="col-md-3 col-6" style={{ display: "flex", alignItems: "center" }}>
                                            <span>OtherCharges: &nbsp;</span>
                                            <span>
                                                <Form.Control size='sm' placeholder='Other Charges' type='text'
                                                    maxLength={5}
                                                    value={pOtherCharges}
                                                    onChange={e => otherChargeChange(e.target.value)}
                                                    disabled={disableOtherCharge || scrMode === "Q"}
                                                />
                                            </span>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col><h3>Payment Details</h3></Col>
                                    </Row>
                                    {
                                        scrMode === "Q" ? null
                                            :
                                            <>
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Payment Type</Form.Label>
                                                            <Form.Select value={pdPaymentType} onChange={e => handlePaymentType(e.target.value)}>
                                                                <option value="">Select</option>
                                                                <option value="CH">Cash</option>
                                                                <option value="BN">Bank</option>
                                                                <option value="CQ">Cheque</option>
                                                                <option value="RC">Recievables</option>
                                                                <option value="PB">Payables</option>
                                                                <option value="AP">Advance Paid</option>
                                                                <option value="AR">Advance Recieved</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Account<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={pdAccount}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, ldgSrchType, sbLocationValue)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleAccountValue}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        pdPaymentType !== "" ?
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Amount<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control placeholder='Amount' value={pdAmount} onChange={e => Common.validateDecValue(e.target.value, setPdAmount)} />
                                                                </Form.Group>
                                                            </Col> : null
                                                    }
                                                </Row>
                                                {
                                                    pdPaymentType === "BN" ?
                                                        <Row>
                                                            <Col className="col-md-3 col-6">
                                                                <Form.Group>
                                                                    <Form.Label>Reference No<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control placeholder='Reference No' maxLength={40} value={pdChequeNum} onChange={e => Common.validateAlpNumValue(e.target.value, setPdChequeNum)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        : pdPaymentType === "CQ" ?
                                                            <Row>
                                                                <Col className="col-md-3 col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>Cheque No<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Control placeholder='Cheque No' type='text' maxLength={10} value={pdChequeNum} onChange={e => Common.validateAlpNumValue(e.target.value, setPdChequeNum)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className="col-md-3 col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>Bank Name<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Control placeholder='Bank Name' type='text' maxLength={50} value={chequeBankName} onChange={e => Common.validateAlpNumValue(e.target.value, setChequeBankName)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className="col-md-3 col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>Cheque Date<span className="colorThemeRed">*</span></Form.Label>
                                                                        <DatePicker className="form-control"
                                                                            selected={chequeDate}
                                                                            onChange={(date) => setChequeDate(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            : null
                                                }
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => addPayment()}>Add</Button>&nbsp;
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Table responsive striped bordered>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Group</th>
                                                    <th>Account</th>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    <th>Cheque/Refernece No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    paymentData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {
                                                                    item.tp_payment_type === "BN"
                                                                        ? "Bank" : item.tp_payment_type === "CQ"
                                                                            ? "Cheque" : item.tp_payment_type === "CH"
                                                                                ? "Cash" : item.tp_payment_type === "RC"
                                                                                    ? "Reveivables" : item.tp_payment_type === "PB"
                                                                                        ? "Payables" : item.tp_payment_type === "AP"
                                                                                            ? "Advance Paid" : item.tp_payment_type === "AR"
                                                                                                ? "Advance Received" : ""
                                                                }
                                                            </td>
                                                            <td>{item.tp_group}</td>
                                                            <td>{item.tp_account}</td>
                                                            <td>{item.mvli_ledger_name}</td>
                                                            <td>{item.tp_amount}</td>
                                                            <td>{item.tp_cheque}</td>
                                                            <td>
                                                                {
                                                                    scrMode !== "Q" ?
                                                                        <span className='colorThemeBlue pointer' onClick={() => deletePayment(item.uniquekey, item.delny)}>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span> : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </>
                }
            </Container>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            <span className="corner-span">{counter}</span>

            <FaView faViewObject={faViewObject} show={showFaView} onHide={() => setShowFaView(false)} />

            <Tran_commission_view show={showCommissionDetail} onHide={() => setShowCommissionDetail(false)} productList={productList} />

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
        </>
    )
}

export default Tran_sb
