import React, { useEffect, useState } from 'react';
import Login_header from '../../Login_header';
import Menu from '../../../mc/Menu';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import * as Picklist from '../../Picklist';
import * as Common from "../../Common";
import $ from "jquery";
import ReactDatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import DialogYesNo from '../../Dialog_Yes_No';
import Dialog from '../../Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function Additional_limit() {

    const sid = sessionStorage.getItem('sessionId');
    const userSrno = sessionStorage.getItem('userSrno')
    const navigate = useNavigate();

    const [scrMode, setScrMode] = useState("");
    const [onceRun, setOnceRun] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [approvalRight, setApprovalRight] = useState([]);
    const [userRight, setUserRight] = useState([])
    const [accountPickValue, setAccountPickValue] = useState("");
    const [amount, setAmount] = useState("");
    const [tillDate, setTillDate] = useState("");
    const [additionalDays, setAdditionalDays] = useState("");
    const [requestRemark, setRequestRemark] = useState("")
    const [locationType, setLocationType] = useState(approvalRight.edit == 1 ? 'G' : 'S')
    const [location, setLocation] = useState("")
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [status, setStatus] = useState('P');
    const [viewStatus, setViewStatus] = useState('');
    const [requesterName, setRequesterName] = useState('')
    const [approverName, setApproverName] = useState('')
    const [approvedDate, setApprovedDate] = useState('')
    const [requestingDate, setRequestingDate] = useState('')
    const [approveRemark, setApproveRemark] = useState('')
    const [srno, setSrno] = useState(0);






    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_ADDITIONAL_CREDIT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_ADDITIONAL_CREDIT_APPROVAl'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setApprovalRight(resp);
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid]);

    function handleAccountPickValue(value) {
        setAccountPickValue(value);
    }

    function addNewHandler() {
        var msg = [], i = 0;
        msg[i++] = Common.getMessage("MSG0006");
        locationType != "G" && !location && (msg[i++] = " Location.");
        if (i > 1) {
            console.log('i', i);
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            setScrMode('A');
        }
    }
    function handleLocationChange(value) {
        setLocationType('S')
        setLocation(value);
    }
    function list() {
        $(".loader").show();
        console.log(locationType, location);
        //VALIDATION START
        var msg = [], i = 0;
        msg[i++] = Common.getMessage("MSG0006");
        locationType !== "G" && !location && (msg[i++] = " Location.");
        if (i > 1) {
            console.log('i', i);
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
            $(".loader").hide();
        } else {
            //VALIDATINO END
            const obj = {
                locationType: locationType === 'G' ? 'G' : 'O',//location type can be 
                location: locationType === 'G' ? 0 : location?.id.split('^')[1],
            }
            Common.callApi(Common.apiCreditLimit, [sid, "getCreditLimitList", JSON.stringify(obj)], result => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                if (resp.err === '') {
                    setDataList(resp.list);
                } else {
                    setMsgText(resp.err);
                    setMsgBox(true);
                }
            });
        }


    }
    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN(Common.getMessage("WRN0000"));
            setMsgBoxYN(true);
        } else {
            setMsgBoxYN(false);
            setMsgTextYN('')
            setScrMode("");
            allValueEmpty();
        }
    }
    function saveBtnHandler() {
        var msg = [], i = 0;
        $(".loader").show();
        msg[i++] = Common.getMessage("MSG0006");
        //validation start
        !accountPickValue && (msg[i++] = " Account.");
        !amount && (msg[i++] = " Amount.");
        !tillDate && (msg[i++] = " Till Date.");
        !additionalDays && (msg[i++] = " Additional Days.");
        !requestRemark && (msg[i++] = " Request Remark.");
        if (i > 1) {
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
            $(".loader").hide();
        }
        //VALIDATION END
        else {
            const group = accountPickValue.id.split('^')[1];
            const account = accountPickValue.id.split('^')[2];
            const obj = {
                locationType: locationType === 'G' ? 'G' : 'O',//location type can be 
                location: locationType === 'G' ? 0 : location.id.split('^')[1],
                group: group,
                account: account,
                amount: amount,
                tillDate: Common.dateYMD(tillDate),
                additionalDays: additionalDays,
                remark: requestRemark,
                status: status,
                approverRemark: approveRemark,
                srno: srno
            }
            Common.callApi(Common.apiCreditLimit, [sid, "addNew", JSON.stringify(obj)], result => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                if (resp.err === '') {
                    setDataList(resp.list);
                    allValueEmpty();
                } else {
                    setMsgText(resp.err);
                    setMsgBox(true);
                }
                setScrMode('')
            });
        }




    }

    function allValueEmpty() {
        setMsgTextYN("")
        setRequestRemark('')
        setAdditionalDays();
        setTillDate();
        setAmount();
        setAccountPickValue();
        setStatus('P');
        setApproveRemark('')
        setSrno(0)
        setViewStatus('')
        // setLocation();
    }

    function handleLocation(val) {
        if (val === 'G') {
            setLocationType("G")
            setLocation('')
        } else {
            setLocationType(val)
        }
    }

    function viewHandler(data) {
        console.log(data.tilldate, "     ", typeof data.tilldate);
        setScrMode('V');
        setLocationType(data.locationtype);
        setLocation({ id: '^' + data.location + '^' + data.branchname + '^', label: data.location + ' ' + data.branchname });
        setStatus(data.status);
        setTillDate(new Date(data?.tilldate));
        setRequestRemark(data.requesterremark)
        setRequesterName(data.requestername)
        setRequestingDate(data.requestingdate)
        setApproverName(data.approvername)
        setApprovedDate(data.approveddate);
        setAdditionalDays(data.additionaldays)
        setAmount(data.additionalamount);
        setAccountPickValue({ id: data.account.split('').join('^'), label: data.account })
        setSrno(data.srno)
        setViewStatus(data.status)
    }
    function yesBtnModelHandler() {
        setScrMode('');
        setMsgBoxYN(false);
        setMsgTextYN('')
        allValueEmpty();

    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Additional Limit</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === '' ?
                        <>
                            {/* FILTER START */}
                            <Row>
                                <Col className='col-md-3'>
                                    <Form.Group>
                                        <Form.Label>Location<span className='text-danger'>*</span></Form.Label>
                                        <Form.Select value={locationType} onChange={e => handleLocation(e.target.value)}
                                            required>
                                            <option value="">Select</option>
                                            {approvalRight.edit === '1' && <option value="G">Global</option>}
                                            <option value="S">Selective</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {locationType === 'S' &&
                                    <Col className='col-md-4'>
                                        <Form.Group>
                                            <Form.Label>&nbsp;</Form.Label>
                                            <AsyncSelect
                                                cacheOptions={false}
                                                defaultOptions={false}
                                                value={location}
                                                getOptionLabel={e => e.label + ' '}
                                                getOptionValue={e => e.id}
                                                loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                onInputChange={Picklist.handleInputChange}
                                                onChange={handleLocationChange}
                                            >
                                            </AsyncSelect>
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                            &nbsp;
                            <Row>
                                <Col>
                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={addNewHandler}>Add New</Button>&nbsp;
                                    <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {/* FILTER END  */}
                            {/* TABLE START  */}
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>Location</th>
                                                <th>Account</th>
                                                <th>Till Date</th>
                                                <th>Additional Days</th>
                                                <th>Requester Name</th>
                                                <th>Requesting Date</th>
                                                <th>Approver Name</th>
                                                <th>Approved Date</th>
                                                <th>Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataList.map((res) => (
                                                    <tr key={res.entity_id}>
                                                        {/* {res.status === 'P' ? */}
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" >
                                                                    <FontAwesomeIcon icon={faEye} onClick={() => viewHandler(res)} />
                                                                </span>
                                                            </td>
                                                            {/* : <td></td>
                                                        } */}
                                                        <td>{res.locationtype === 'G' ? 'G' : res.location}</td>
                                                        <td>{res.account}</td>
                                                        <td>{Common.dateDMYStr(res.tilldate)}</td>
                                                        <td>{res.additionaldays}</td>
                                                        <td>{res.requestername}</td>
                                                        <td>{Common.dateDMYStr(res.requestingdate)}</td>
                                                        <td>{res.approvername}</td>
                                                        <td>{res.approverdate}</td>
                                                        <td>{res.status === 'P' ? 'Pending' : res.status === 'A' ? 'Approved' : 'Rejected'}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            {/* TBALE END  */}
                        </> :
                        <>{/* ADD NEW VIEW UPDATE PAGE */}
                            <Row>
                                <Col className='col-md-3 col-6'><Form.Label><><b> Srno : </b> {srno}</></Form.Label> </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>
                                        {(approvalRight.edit === '1' && locationType === 'G') ? <> <b>Location : </b>Global</> : <><b> Location : </b>{location?.label}</>}
                                    </Form.Label>
                                </Col>
                            </Row>
                            {scrMode != 'A' &&
                                <>
                                    <Row>
                                        <Col><Form.Label><b>Requester Name : </b>{requesterName} </Form.Label></Col>
                                        <Col><Form.Label><b>Requesting Date : </b>{Common.dateDMYStr(requestingDate)}</Form.Label></Col>
                                        <Col><Form.Label><b>Approver Name : </b>{approverName}  </Form.Label></Col>
                                        <Col><Form.Label><b>Approving Date : </b>{Common.dateDMYStr(approvedDate)} </Form.Label></Col>
                                    </Row>
                                </>
                            }
                            &nbsp;
                            <Row>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Form.Label>Account {requesterName.split(' ')[1]} {userSrno}<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={accountPickValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, 1)} // location = 1 , 2
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleAccountPickValue}
                                            isDisabled={scrMode === 'V' || viewStatus ==='P'}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Form.Label>Amount<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            value={amount}
                                            maxLength={Common.maximumLength}
                                            onChange={(e) => Common.validateNumValue(e.target.value, setAmount)}
                                            placeholder="Amount"
                                            disabled={scrMode === 'V' || viewStatus === 'P'}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Form.Label>Additional Days<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            value={additionalDays}
                                            maxLength={Common.maximumLength}
                                            onChange={(e) => Common.validateNumValue(e.target.value, setAdditionalDays)}
                                            placeholder="Additional Days"
                                            disabled={scrMode === 'V' || viewStatus === 'P'}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col className="col-md-4 col-6">
                                    <Form.Group className='al_date_picker_width'>
                                        <Form.Label>Till Date<span className='colorThemeRed'>*</span></Form.Label><br></br>
                                        <ReactDatePicker className="form-control"
                                            selected={tillDate}
                                            onChange={(date) => { setTillDate(date) }}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            disabled={scrMode === 'V' || viewStatus === 'P'}
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Form.Label>Request Remark<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            value={requestRemark}
                                            // maxLength={Common.maximumLength}
                                            onChange={(e) => Common.validateAlpValue(e.target.value, setRequestRemark)}
                                            placeholder="Request Remark"
                                            disabled={scrMode === 'V' || viewStatus === 'P'}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            &nbsp;
                            { srno!=0 && scrMode!='A' &&
                                <Row>
                                    <Col className='col-md-4 col-6'>
                                        <Form.Group>
                                            <Form.Label>Status<span className='text-danger'>*</span></Form.Label>
                                            <Form.Select value={status} onChange={e => setStatus(e.target.value)}
                                                disabled={scrMode === 'V' || userRight.edit=='1'}
                                                required>
                                                <option value="">Select</option>
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                                <option value="R">Rejected</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-md-4 col-6'>
                                        <Form.Group>
                                            <Form.Label>Approve Remark<span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                value={approveRemark}
                                                // maxLength={Common.maximumLength}
                                                onChange={(e) => Common.validateAlpValue(e.target.value, setApproveRemark)}
                                                placeholder="Approve Remark"
                                                disabled={scrMode === 'V'}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                            &nbsp;
                            <Row>
                                <Col>
                                    {scrMode === 'A' || scrMode === 'E' ?
                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={saveBtnHandler}>Save</Button> :
                                        (status != 'P' || approvalRight.edit === '1') && <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => setScrMode('E')}>Edit</Button>}&nbsp;
                                    <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>
                                </Col>
                            </Row>
                        </>
                }
            </Container>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={yesBtnModelHandler} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
        </>
    )
}

export default Additional_limit
