import React, { useEffect, useState } from 'react'
import Login_header from '../../Login_header'
import Menu from '../../Menu'
import { Button, Col, Container, FloatingLabel, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap'
import AsyncSelect from 'react-select/async';
import * as Picklist from '../../Picklist';
import DatePicker from 'react-datepicker';
import * as Common from '../../Common';
import { json, useNavigate } from 'react-router-dom';
import Dialog from '../../Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
import ActivityLog from '../../ActivityLog';
import { formToJSON } from 'axios';


const Tran_module_payment = () => {

    const [scrMode, setScrMode] = useState('');
    const [radioType, setRadioType] = useState('N');
    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [userRight, setUserRight] = useState([]);
    const [userApproveL1, setUserApproveL1] = useState([]);
    const [userApproveL2, setUserApproveL2] = useState([]);
    const [downloadBankRight, setDownloadBankRight] = useState([]);
    const [downloadFaRight, setDownloadFaRight] = useState([]);
    const [userEncashmentL1, setUserEncashmentL1] = useState([]);
    const [userEncashmentL2, setUserEncashmentL2] = useState([]);
    const [userThirdPartyL1, setUserThirdPartyL1] = useState([]);
    const [userThirdPartyL2, setUserThirdPartyL2] = useState([]);
    const [userFundTransferL1, setUserFundTransferL1] = useState([]);
    const [userFundTransferL2, setUserFundTransferL2] = useState([]);
    const [userExpenseL1, setUserExpenseL1] = useState([]);
    const [userExpenseL2, setUserExpenseL2] = useState([]);


    const [onceRun, setOnceRun] = useState(false);

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [tpDate, setTPDate] = useState(new Date());
    const [tpLocationValue, setTPLocationValue] = useState('');
    const [tpLocationCode, setTPLocationCode] = useState('');
    const [tpLocationName, setTPLocationName] = useState('');

    const [tpToLocationValue, setTPToLocationValue] = useState('');
    const [tpToLocationCode, setTPToLocationCode] = useState('');
    const [tpToLocationName, setTPToLocationName] = useState('');

    const [tpFromSrno, setTPFromSrno] = useState('');
    const [tpToSrno, setTPToSrno] = useState('');
    const [tpFromDate, setTPFromDate] = useState(new Date());
    const [tpToDate, setTPToDate] = useState(new Date());
    const [tpStatus, setTPStatus] = useState('N');


    const [tpUploadDoc, setTPUploadDoc] = useState("");
    const [tpRemark, setTPRemark] = useState('');
    const [tpAmount, setTPAmount] = useState('');
    const [tpCategory, setTPCategory] = useState('E');

    const [tpEncashmentType, setTPEncashmentType] = useState('PP'); // if tpCategory => encashment
    const [tpEncashmentNum, setTPEncashmentNum] = useState(''); // if tpCategory => encashment


    const [tpDepositorName, setTPDepositorName] = useState(''); // if tpCategory => third party payment
    const [tpTransactionDesc, setTPTransactionDesc] = useState('');
    const [tpRealationshipMang, setTPRealationshipMang] = useState('');
    const [tpDocumentDesc, setTPDocumentDesc] = useState('');

    const [tpAccountName, setTPAccountName] = useState('');
    const [tpAccountNum, setTPAccountNum] = useState('');
    const [tpIfsc, setTPIfsc] = useState('');

    const [tpBank, setTPBank] = useState(null);

    const [tpBankGroup, setTPBankGroup] = useState('');
    const [tpBankAccount, setTPBankAccount] = useState('');

    const [tpToBank, setTPToBank] = useState(null);

    const [tpToBankGroup, setTPToBankGroup] = useState('');
    const [tpToBankAccount, setTPToBankAccount] = useState('');

    const [uploadedDoc, setUploadedDoc] = useState([]);

    let msg = [], i = 0;


    const [documentToView, setDocumentToView] = useState("");
    const [documentTypeToView, setDocumentTypeToView] = useState("");
    const [documentDescToView, setDocumentDescToView] = useState("");
    const [documentNameToView, setDocumentNameToView] = useState("");
    const [showDocument, setShowDocument] = useState(false);

    const [tpModuleData, setTPModuleData] = useState([]);
    //
    const [tpCategoryOptions, setTPCategoryOptions] = useState([]);



    const [srNo, setSrNo] = useState("0");
    const [tpType, setTPType] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [acRemark, setAcRemark] = useState("");
    const [code, setCode] = useState(0);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);

    const [tpComment, setTPComment] = useState('');
    const [tpCommentData, setTPCommentData] = useState([]);


    const [uniqueKey, setUniqueKey] = useState(0);

    const [lineno, setLineno] = useState(0);

    const [count, setCount] = useState(0);

    const [tpUploadDocDesc, setTPUploadDocDesc] = useState('');

    const [resStatus, setResStatus] = useState('');

    const [resDate, setResDate] = useState('');


    const [tpBankData, setTPBankData] = useState([]);

    const [allChecked, setAllChecked] = useState(false);

    const [tpGst, setTpGst] = useState(0);
    const [tpTds, setTpTds] = useState(0);
    const [tpOtherCharges, setTpOtherCharges] = useState(0);
    const [tpNetPayable, setTpNetPayable] = useState(0);

    const [tpFaFlag, setTpFaFlag] = useState('C');
    const [tpFaLedgerValue, setTpFaLedgerValue] = useState(null);
    const [tpFaGroup, setTpFaGroup] = useState('');
    const [tpFaAccount, setTpFaAccount] = useState('');
    const [tpFaAmount, setTpFaAmount] = useState('');

    const [tpFaEntry, setTpFaEntry] = useState([]);

    const [tpFaMaxLine, setTpFaMaxLine] = useState(0);

    const [totalAmount, setTotalAmount] = useState(0);

    const [tpParty, setTpParty] = useState(null);

    const [tpPartyGroup, setTpPartyGroup] = useState(0);
    const [tpPartyAccount, setTpPartyAccount] = useState(0);

    const [totalCredit, setTotalCredit] = useState(0);
    const [totalDebit, setTotalDebit] = useState(0);

    const [tpApprovalOne, setTPApprovalOne] = useState('');
    const [tpApprovalTwo, setTPApprovalTwo] = useState('');

    const [showDownloadModal, setShowDownloadModal] = useState(false);

    const [tpFaDownloadOption, setTpFaDownloadOption] = useState('N');

    const [tpFromFaDownload, setTpFromFaDownload] = useState(new Date());
    const [tpToFaDownload, setTpToFaDownload] = useState(new Date());


    const [bankDetail, setBankDetail] = useState();

    const [isDownload, setIsDownload] = useState(true);

    const [tpMaker, setTpMaker] = useState('');

    const [tpChecker1, setTpChecker1] = useState('');
    const [tpChecker2, setTpChecker2] = useState('');

    const [tpRef, setTpRef] = useState('');
    const [tpSubCategory, setTpSubCategory] = useState(null);

    const [paymentOption, setPaymentOption] = useState("A");
    const [paymentPayType, setPaymentPayType] = useState("PM");
    const [tpFaDownloadType, settpFaDownloadType] = useState("CM");
    const [tpBKNumber, setTPBKNumber] = useState('');
    const [locationOneAll, setLocationOneAll] = useState("O");


    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setTPLocationValue(response);
            response != null && handleChangeLocation(response);
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_MODULE_PAYMENT'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
                if (!(resp.add === "1" && radioType == 'N')) {
                    setRadioType("S");
                }
            } else {
                navigate('/'); // no query right
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_APPROVE_L1'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserApproveL1(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_APPROVE_L2'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserApproveL2(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_BANK_FILE_DOWNLOAD'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setDownloadBankRight(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_FA_DOWNLOAD'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setDownloadFaRight(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_ENCASHMENT_L1'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserEncashmentL1(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_ENCASHMENT_L2'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserEncashmentL2(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_THIRD_PARTY_L1'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserThirdPartyL1(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_THIRD_PARTY_L2'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserThirdPartyL2(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_FUND_TRANSFER_L1'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserFundTransferL1(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_FUND_TRANSFER_L2'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserFundTransferL2(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_EXPENSE_L1'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserExpenseL1(resp);
            }
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_EXPENSE_L2'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserExpenseL2(resp);
            }
        });

        const obj = {
            group: 4505,
            srno: 1005,
            account_num: 12345,
            location: tpLocationCode
        }

        Common.callApi(Common.apiLedger, [sid, 'activebank', JSON.stringify(obj)], function (result) {
            const response = JSON.parse(result);
            console.log(response);
            setBankDetail(response);
        })

        Common.callApi(Common.apiTranModulePayment, [sid, 'getCategory'], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setTPCategoryOptions(resp);
        })

    }, []);

    const handleChangeLocation = (value) => {
        setTPModuleData([]);
        setTPLocationValue(value);
        const loc = value.id.split('^');
        // console.log(loc, 'location');
        setTPLocationCode(loc[1]);
        setTPLocationName(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleChangeToLocation = (value) => {
        setTPToLocationValue(value);
        setTPToBank(null);
        const loc = value.id.split('^');
        // console.log(loc, 'location');
        setTPToLocationCode(loc[1]);
        setTPToLocationName(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }

    const addNew = () => {
        var d = Common.dateYMD(tpDate);
        if (!tpLocationValue || !tpDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!tpLocationValue ? "Location." : '');
            msg[i++] = (!tpDate ? "Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (d > endDate || d < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        }
        else {
            setUniqueKey(Common.getRandomString(20));
            setSrNo(0);
            setScrMode('A');
            setTPRemark('');
            setTPAmount('');
            setTPUploadDoc('');
            setTPCategory('E');
            setTPEncashmentType('PP');
            setTPEncashmentNum('');
            setTPDepositorName('');
            setTPTransactionDesc('');
            setTPRealationshipMang('');
            setTPDocumentDesc('');
            setTPAccountName('');
            setTPAccountNum('');
            setTPIfsc('');
            setTPBank(null);
            setTPToBank(null);
            setTPBankGroup('');
            setTPBankAccount('');
            setUploadedDoc([]);
            setCode(0);
            setLineno(0);
            setTPComment('');
            setTPCommentData([]);
            setResStatus('N');
            setResDate(Common.dateDMY(tpDate));
            setTpRef('');
            setTPToLocationValue(null);
            setTpChecker1('');
            setTpChecker2('');
            setTpMaker('');
            setTPBKNumber('');
        }

    }

    const tpList = () => {
        $('.loader').show();

        var fromDate = Common.dateYMD(tpFromDate);
        var toDate = Common.dateYMD(tpToDate);


        if (!tpLocationValue || (radioType === 'S' && !tpFromSrno) || (radioType === 'S' && !tpToSrno)
            || (radioType === 'D' && !tpFromDate) || (radioType === 'D' && !tpToDate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!tpLocationValue ? "Location." : '');
            msg[i++] = ((radioType === 'S' && !tpFromSrno) ? "From Srno." : '');
            msg[i++] = ((radioType === 'S' && !tpToSrno) ? "To Srno." : '');
            msg[i++] = ((radioType === 'D' && !tpFromDate) ? "From Date." : '');
            msg[i++] = ((radioType === 'D' && !tpToDate) ? "To Date." : '');
            $('.loader').hide();
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (radioType === 'D' && (toDate > endDate || fromDate < startDate)) {
            $('.loader').hide();
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            const obj = {
                tmp_location: tpLocationCode,
                radioType: radioType,
                tpFromSrno: tpFromSrno,
                tpToSrno: tpToSrno,
                tpFromDate: Common.dateYMD(tpFromDate),
                tpToDate: Common.dateYMD(tpToDate),
                tpStatus: tpStatus,
                tmp_type: 'PM',
                paymentOption: paymentOption,
                paymentPayType: paymentPayType,
                locationOneAll: locationOneAll
            }
            Common.callApi(Common.apiTranModulePayment, [sid, 'listEntry', JSON.stringify(obj)], function (result) {
                let resp = JSON.parse(result);
                // console.log(resp);
                $('.loader').hide();
                setTPModuleData(resp);
                setTPBankData([]);
                setIsDownload(true);
            })
        }
    }

    const handleChangeBank = (value) => {
        setTPBank(value);
        // console.log(value);
        const bank = value.id.split('^');
        // console.log(bank, 'bank');
        setTPBankGroup(bank[1]);
        setTPBankAccount(bank[2]);
    }

    const handleChangeBankDetail = (value) => {
        setBankDetail(value);
        // console.log(value);
        const bank = value.id.split('^');
        // console.log(bank, 'bank');

    }

    const handleChangeToBank = (value) => {
        setTPToBank(value);
        // console.log(value);
        const bank = value.id.split('^');
        // console.log(bank, 'bank');
        setTPToBankGroup(bank[1]);
        setTPToBankAccount(bank[2]);
        setTPAccountNum(bank[5]);
        setTPIfsc(bank[6]);

    }

    const saveData = () => {
        const obj = {
            remark: acRemark,
            activitytype: code == 1 ? 'MODIFY' : 'CREATE',
            subtranno: code,
            trannumber: srNo,
            trantype: 'PM',
            trancategory: 'T',
            branch: tpLocationCode,

            srno: srNo,
            uniquekey: uniqueKey,
            tmp_location: tpLocationCode,
            tmp_to_location: tpToLocationCode,
            tmp_request_date: Common.dateYMD(tpDate),
            tmp_type: 'PM',
            tmp_remark: tpRemark,
            tmpUploadDoc: tpUploadDoc,
            tmp_paycategory: tpCategory,
            tmp_encashment_number: !tpEncashmentNum ? '0' : tpEncashmentNum,
            tmp_encashment_type: tpEncashmentType,
            tmp_tpdepositor_name: tpDepositorName,
            tmp_tptransaction_desc: tpTransactionDesc,
            tmp_tprelationship_manager: tpRealationshipMang,
            tmp_document_collected: tpDocumentDesc,
            tmp_from_bank: tpBank,
            tmp_from_ftbank_group: !tpBankGroup ? '0' : tpBankGroup,
            tmp_from_ftbank_account: !tpBankAccount ? '0' : tpBankAccount,
            tmp_to_bank: tpBank,
            tmp_to_ftbank_group: !tpToBankGroup ? '0' : tpToBankGroup,
            tmp_to_ftbank_account: !tpToBankAccount ? '0' : tpToBankAccount,
            tmp_refCode: tpRef,
            tmp_amount: tpAmount,
            tmp_account_name: tpAccountName.toUpperCase(),
            tmp_account_number: tpAccountNum.toUpperCase(),
            tmp_ifsc: tpIfsc.toUpperCase(),
            uploadedDoc: uploadedDoc,
            radioType: radioType,
            tpFromSrno: tpFromSrno,
            tpToSrno: tpToSrno,
            tpFromDate: tpFromDate,
            tpToDate: tpToDate,
            tpCommentData: tpCommentData,
            tpStatus: tpStatus,
            resStatus: resStatus,
            tp_user: sessionStorage.getItem('userSrno'),
            tmp_bk_number: tpBKNumber
        }

        Common.callApi(Common.apiTranModulePayment, [sid, 'saveEntry', JSON.stringify(obj)], function (result) {
            let resp = JSON.parse(result);
            // console.log(resp);
            setTPModuleData(resp);
            $('.loader').hide();
            // setSrNo(resp.tmp_srno);
            setSrNo('0');
            setUploadedDoc([]);
            setScrMode('');
            setLineno(0);
            setTPCommentData([]);
            setShowModal(false);
            setAcRemark('');
        })
    }


    const handleSave = () => {
        $('.loader').show();

        if ((!tpRemark && tpCategory !== 'F') || (uploadedDoc.length < 1 && tpCategory !== 'F') || (tpCategory === 'E' && !tpEncashmentNum) || (tpCategory === 'T' && !tpDepositorName) || (tpCategory === 'T' && !tpTransactionDesc)
            || (tpCategory === 'T' && !tpRealationshipMang) || (tpCategory === 'T' && !tpDocumentDesc) || (tpCategory === 'F' && !tpBank) || (tpCategory === 'F' && !tpToBank) || (tpCategory === 'F' && !tpRef) || !tpAmount || (!tpAccountName && tpCategory !== 'F')
            || (!tpAccountNum && tpCategory !== 'F') || (!tpIfsc && tpCategory !== 'F')) {
            msg[i++] = Common.getMessage("MSG0006");

            msg[i++] = ((tpCategory === 'E' && !tpEncashmentNum) ? "Encashment Number." : '');

            msg[i++] = ((tpCategory === 'T' && !tpDepositorName) ? "Depositor Name." : '');
            msg[i++] = ((tpCategory === 'T' && !tpTransactionDesc) ? "Transaction Description." : '');
            msg[i++] = ((tpCategory === 'T' && !tpRealationshipMang) ? "Relationship Manager." : '');
            msg[i++] = ((tpCategory === 'T' && !tpDocumentDesc) ? "Document Description." : '');

            msg[i++] = ((tpCategory === 'F' && !tpBank) ? "From Bank." : '');
            msg[i++] = ((tpCategory === 'F' && !tpToBank) ? "To Bank." : '');
            msg[i++] = ((tpCategory === 'F' && !tpRef) ? "Reference No." : '');

            msg[i++] = (!tpAmount ? "Amount." : '');
            msg[i++] = ((!tpAccountName && tpCategory !== 'F') ? "Account Name." : '');
            msg[i++] = ((!tpAccountNum && tpCategory !== 'F') ? "Account Number." : '');
            msg[i++] = ((!tpIfsc && tpCategory !== 'F') ? "IFSC." : '');
            msg[i++] = ((uploadedDoc.length < 1 && tpCategory !== 'F') ? "Upload at least one document Document." : '');
            msg[i++] = (!tpRemark && tpCategory !== 'F' ? "Narration." : '');
            $('.loader').hide();
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (scrMode === 'E' && acRemark === '') {
            $('.loader').hide();
            setShowModal(true);
        } else {
            if (tpCategory === 'F' && (tpBankGroup === tpToBankGroup) && (tpBankAccount === tpToBankAccount)) {
                setMsgText(`From Bank and To Bank can't be same`)
                setMsgBox(true);
                $('.loader').hide();
            } else {
                saveData();
            }
            // $('.loader').hide();

        }
    }

    const btnSaveModal = () => {
        $('.loader').show();
        saveData();
    }

    const handleAddDoc = () => {
        $('.loader').show();

        if (document.getElementById('uploadPaymentModuleDoc').files[0] === undefined) {
            $('.loader').hide();
            setMsgText("Please select a file to upload.");
            setMsgBox(true);
        } else {
            var tempArray = document.getElementById('uploadPaymentModuleDoc').files[0].name.split('.');
            if (tempArray.length > 2) {
                $('.loader').hide();
                setMsgText("(.) not allowed in file name.");
                setMsgBox(true);
            } else if (document.getElementById('uploadPaymentModuleDoc').files[0].size > 10 * 1024 * 1024) {  // 10 MB
                $('.loader').hide();
                setMsgText("Please select a file with in 10 MB size.");
                setMsgBox(true);
            } else if (!tpUploadDocDesc) {
                $('.loader').hide();
                setMsgText("Please add document description.");
                setMsgBox(true);
            }
            // else if (tempArray[1] != 'xlsx') {
            //     // $('.loader').hide();
            //     setMsgText("File extension should be in xlsx format");
            //     setMsgBox(true);
            // }
            else {

                const obj = {
                    uniquekey: uniqueKey,
                    location: tpLocationCode,
                    srno: srNo,
                    type: 'PM',
                    uploadType: "uploadPaymentModuleDoc",
                    pageright: "TRAN_MODULE_PAYMENT",
                    sid: sid,
                    file: tpUploadDoc,
                    desc: tpUploadDocDesc
                }

                console.log(obj);
                Common.uploadApi(JSON.stringify(obj), 'uploadPaymentModuleDoc', (result) => {
                    let resp = JSON.parse(result);
                    // console.log(resp);
                    $('.loader').hide();
                    setUploadedDoc(resp.docdetail)
                    setTPUploadDoc('');
                    setTPUploadDocDesc('');
                })
            }
        }
    }

    const handleViewDoc = (line, doc_ext, srno, type, location) => {
        // console.log(doc_ext);
        if (doc_ext == "xlsx") {
            setMsgText("You can't view " + doc_ext + " format");
            setMsgBox(true);
        } else if (doc_ext == "xls") {
            setMsgText("You can't view " + doc_ext + " format");
            setMsgBox(true);
        } else {
            const obj = {
                line: line,
                srno: srno,
                type: 'PM',
                loc: tpLocationCode,
                name: 'paymentmoduledoc',
                pageright: "TRAN_MODULE_PAYMENT",
                scrMode: scrMode,
            }
            Common.callDocumentViewApi(Common.apiDocument, [sid, 'docView', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setDocumentToView(resp.bs64);
                setDocumentTypeToView(resp.typ);
                setDocumentDescToView(resp.desc);
                setDocumentNameToView(resp.fname);
                setShowDocument(true);
            });
        }
    }

    const btnDocumentDownload = (line, srno, type, loc) => {

        var object1 = {
            srno: srno,
            type: type,
            loc: loc,
            scrMode: scrMode,
            line: line,
            name: 'paymentmoduledoc',
            pageright: "TRAN_MODULE_PAYMENT"
        }
        Common.callDownloadApiPost(Common.apiDocument, "post", [sid, 'docdownload', JSON.stringify(object1)]);
    }

    const handleDeleteDoc = (line, srno, type, loc) => {
        $('.loader').show();
        const obj = {
            line: line,
            srno: srno,
            type: 'PM',
            location: loc,
            uniquekey: uniqueKey,
            scrMode: scrMode
        }
        Common.callApi(Common.apiTranModulePayment, [sid, "deleteUploadDoc", JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result)
            setUploadedDoc(resp);
            $('.loader').hide();
        })
    }

    const viewTPData = (srno, location, type, date) => {
        $('.loader').show();
        setSrNo(srno);
        setTPType(type);

        const obj = {
            srno: srno,
            location: location,
            type: type,
            date: date
        }

        Common.callApi(Common.apiTranModulePayment, [sid, "viewModulePymentData", JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            // console.log(resp);

            let data = resp.modulePaymentData[0];

            setUploadedDoc(resp.docData);

            setLineno(resp.commentMaxLine);

            setTPCommentData(resp.commentData);

            setTpFaMaxLine(resp.maxFaEntryLine);
            setTpFaEntry(resp.faEntryData);
            calculateTotal(resp.faEntryData);

            setCode(1);
            if (data.tmp_type === "CM") setTPCategory("CM");
            else {
                console.log(data.tmp_paycategory);
                // setTPCategoryOptions({tpc_value:data.tmp_paycategory})
                setTPCategory(data.tmp_paycategory);
            }

            //setTPViewType(data.tmp_type);
            setTPApprovalOne(data.tmp_approval_level1);
            setTPApprovalTwo(data.tmp_approval_level2);
            setResStatus(data.tmp_status);
            setResDate(data.formatted_date);
            setTPStatus(data.tmp_status);
            setTPRemark(data.tmp_remark);
            setTpMaker(data.maker_name);
            setTpChecker1(data.checker1_name);
            setTpChecker2(data.checker2_name)
            setTPAmount(data.tmp_amount);
            setTpGst(data.tmp_gst);
            setTpTds(data.tmp_tds);
            setTpNetPayable(data.tmp_netpayable);
            setTpOtherCharges(data.tmp_othcharges);
            setTPAccountName(data.tmp_account_name);
            setTPAccountNum(data.tmp_account_number);
            setTPIfsc(data.tmp_ifsc);
            setTPEncashmentType(data.tmp_encashment_type);
            setTPEncashmentNum(data.tmp_encashment_number);
            setTPDepositorName(data.tmp_tpdepositor_name);
            setTPDocumentDesc(data.tmp_document_collected);
            setTPRealationshipMang(data.tmp_tprelationship_manager);
            setTPTransactionDesc(data.tmp_tptransaction_desc);
            setTPBank({ id: +'^' + data.tmp_from_ftbank_group + '^' + data.tmp_from_ftbank_account + '^' + data.frombankname + '^' + data.frombanktype, label: data.tmp_from_ftbank_group + ' ' + data.tmp_from_ftbank_account + ' ' + data.frombankname });
            setTPToBank({ id: +'^' + data.tmp_to_ftbank_group + '^' + data.tmp_to_ftbank_account + '^' + data.tobankname + '^' + data.tobanktype, label: data.tmp_to_ftbank_group + ' ' + data.tmp_to_ftbank_account + ' ' + data.tobankname });
            setTPBKNumber(data.tmp_bk_number);

            if (data.tmp_party_group !== '0' && data.tmp_party_account !== '0') {
                setTpParty({ id: +'^' + data.tmp_party_group + '^' + data.tmp_party_account + '^' + data.partybankname + '^' + data.partybanktype, label: data.tmp_party_group + ' ' + data.tmp_party_account + ' ' + data.partybankname })
            }

            if (data.tmp_paycategory === 'F') {
                setTpParty({ id: +'^' + data.tmp_to_ftbank_group + '^' + data.tmp_to_ftbank_account + '^' + data.tobankname + '^' + data.tobanktype, label: data.tmp_to_ftbank_group + ' ' + data.tmp_to_ftbank_account + ' ' + data.tobankname })

                setTpPartyGroup(data.tmp_to_ftbank_group);
                setTpPartyAccount(data.tmp_to_ftbank_account);
                setTpRef(data.tmp_ref_no);
                setTPToLocationValue({ label: data.tmp_to_location + ' ' + data.to_location_name });
            }
            $('.loader').hide();
            setScrMode('Q');
        })

    }

    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: 'PM', trannumber: srNo, trancategory: "T" });
    }

    const handleAddComment = () => {
        $('.loader').show();

        if (!tpComment) {
            $('.loader').hide();
            setMsgText('Add Comment');
            setMsgBox(true);
        } else {
            setLineno(lineno * 1 + 1);
            const obj = {
                remark: 'Comment added',
                activitytype: 'MODIFY',
                subtranno: 1,
                trannumber: srNo,
                trantype: 'PM',
                trancategory: 'T',
                branch: tpLocationCode,

                tc_line_no: lineno * 1 + 1,
                location: tpLocationCode,
                srno: srNo,
                type: 'PM',

                tc_branch: tpLocationCode,
                srno: srNo,
                tc_type: 'PM',
                tc_comment: tpComment,
                tc_user_srno: sessionStorage.getItem('userSrno'),
                entity_name: tpLocationName,
                um_user_name: sessionStorage.getItem('userName'),
                tc_timestamp: formatDate(new Date())
            }

            $('.loader').hide();
            if (scrMode === 'A' || scrMode === 'E') {
                setTPCommentData(Common.arrayAddItem(tpCommentData, obj));
            } else if (scrMode === 'Q') {
                Common.callApi(Common.apiTranModulePayment, [sid, 'addComment', JSON.stringify(obj)], (result) => {
                    let resp = JSON.parse(result);
                    setTPCommentData(resp);
                })
            }
            setTPComment('');
        }

    }

    // const handleDeleteComment = (line) => {
    //     setCount(count + 1);
    //     setTPCommentData(Common.arrayRemoveItem(tpCommentData, "tc_line_no", line));
    // }

    const handleApprovalOne = () => {
        console.log(userEncashmentL1.query);

        $('.loader').show();

        if (totalAmount != 0) {
            $('.loader').hide();
            setMsgText('Total Amount should be equal to total net payable');
            setMsgBox(true);
        } else if (tpNetPayable < 0) {
            $('.loader').hide();
            setMsgText(' Net Payable should be greater than zero');
            setMsgBox(true);
        } else if ((userEncashmentL1.query !== '1' && tpCategory === 'E') || (userThirdPartyL1.query !== '1' && tpCategory === 'T') || (userFundTransferL1.query !== '1' && tpCategory === 'F')
            || (userExpenseL1.query !== '1' && tpCategory === 'Ex')) {
            $('.loader').hide();
            setMsgText(`You can't approve this entry`);
            setMsgBox(true);
        } else {
            const obj = {
                remark: 'Approval level 1',
                activitytype: 'MODIFY',
                subtranno: 1,
                trannumber: srNo,
                trantype: 'PM',
                trancategory: 'T',
                branch: tpLocationCode,

                tmp_srno: srNo,
                tmp_type: 'PM',
                tmp_location: tpLocationCode,
                radioType: radioType,
                tpFromSrno: tpFromSrno,
                tpToSrno: tpToSrno,
                tpFromDate: Common.dateYMD(tpFromDate),
                tpToDate: Common.dateYMD(tpToDate),
                tpStatus: tpStatus,
                tpGst: tpGst,
                tpTds: tpTds,
                tpOtherCharges: tpOtherCharges,
                tpNetPayable: tpNetPayable,
                tpPartyGroup: tpPartyGroup,
                tpPartyAccount: tpPartyAccount,
                tpChecker1: sessionStorage.getItem('userSrno')
            }
            Common.callApi(Common.apiTranModulePayment, [sid, 'approveLevelOne', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setTPModuleData(resp);
                $('.loader').hide();
                setScrMode('');

            })
        }
    }

    const handleApprovalTwo = () => {
        $('.loader').show();

        if (totalAmount != 0) {
            $('.loader').hide();
            setMsgText('Total Amount should be equal to total net payable');
            setMsgBox(true);
        } else if (tpNetPayable < 0) {
            $('.loader').hide();
            setMsgText(' Net Payable should be greater than zero');
            setMsgBox(true);
        } else if (!tpParty) {
            $('.loader').hide();
            setMsgText('Please select party');
            setMsgBox(true);
        } else if ((userEncashmentL2.query !== '1' && tpCategory === 'E') || (userThirdPartyL2.query !== '1' && tpCategory === 'T') || (userFundTransferL2.query !== '1' && tpCategory === 'F')
            || (userExpenseL2.query !== '1' && tpCategory === 'Ex')) {
            $('.loader').hide();
            setMsgText(`You can't approve this entry`);
            setMsgBox(true);
        } else if (tpCategory === "T" && tpBKNumber === "") {
            $('.loader').hide();
            setMsgText('Please fill BK number.');
            setMsgBox(true);
        } else {
            const obj = {
                remark: 'Approval level 2',
                activitytype: 'MODIFY',
                subtranno: 1,
                trannumber: srNo,
                trantype: 'PM',
                trancategory: 'T',
                branch: tpLocationCode,

                tmp_srno: srNo,
                tmp_type: 'PM',
                tmp_location: tpLocationCode,
                radioType: radioType,
                tpFromSrno: tpFromSrno,
                tpToSrno: tpToSrno,
                tpFromDate: Common.dateYMD(tpFromDate),
                tpToDate: Common.dateYMD(tpToDate),
                tpStatus: tpStatus,
                tpGst: tpGst,
                tpTds: tpTds,
                tpOtherCharges: tpOtherCharges,
                tpNetPayable: tpNetPayable,
                tpPartyGroup: tpPartyGroup,
                tpPartyAccount: tpPartyAccount,
                tpChecker2: sessionStorage.getItem('userSrno'),
                tpBKNumber: tpBKNumber
            }
            Common.callApi(Common.apiTranModulePayment, [sid, 'approveLevelTwo', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setTPModuleData(resp);
                $('.loader').hide();
                setScrMode('');
            })
        }
    }

    const updateCheck = (index, e, name) => {
        const isChecked = e.target.checked;
        // console.log(isChecked);

        // Update tpModuleData to reflect the checkbox change
        const updatedModuleData = tpModuleData.map((item, i) => {
            if (index === i) {
                return { ...item, [name]: isChecked ? '1' : '0' };
            }
            return item;
        });

        setTPModuleData(updatedModuleData);

        // Update tpBankData to include only checked items
        const updatedBankData = updatedModuleData.filter(item => item.ischecked === '1');
        setTPBankData(updatedBankData);
    };

    const downloadBankFile = () => {
        const bank = bankDetail.id.split('^');

        if (tpBankData.length === 0) {
            setMsgBox(true);
            setMsgText('Please select at least one record');
        }

        else if (!bankDetail) {
            setMsgText('Please select from bank');
            setMsgBox(true);
        } else if (bank[7] !== 'AXIS') {
            setShowDownloadModal(false);
            setMsgText(`You can't download any other than Axis bank files `);
            setMsgBox(true);
        }
        else {
            console.log(bank);
            const obj = {
                // remark: 'Payment Transfer',
                // activitytype: 'MODIFY',
                // subtranno: 1,
                // // trannumber: srNo,
                // trantype: 'PM',
                // trancategory: 'T',
                // branch: tpLocationCode,

                tpBankData: tpBankData,
                tmp_location: tpLocationCode,
                radioType: radioType,
                tpFromSrno: tpFromSrno,
                tpToSrno: tpToSrno,
                tpFromDate: Common.dateYMD(tpFromDate),
                tpToDate: Common.dateYMD(tpToDate),
                tpStatus: tpStatus,
                tmp_type: 'PM',
                tmp_from_bank_group: bank[9],
                tmp_from_bank_account: bank[8],
                tmp_account_number: bank[5],
                tmp_ifdc: bank[6],
                paymentOption: paymentOption,
                paymentPayType: paymentPayType,
                locationOneAll: locationOneAll
            };

            Common.callDownloadApiPost(Common.apiTranModulePayment, "post", [sid, 'downloadbankfile', JSON.stringify(obj)]);

            setShowDownloadModal(false);
            setIsDownload(false);
        }
    };

    const downloadFaFile = () => {

        const obj = {
            remark: 'Payment Transfer',
            activitytype: 'MODIFY',
            subtranno: 1,
            // trannumber: srNo,
            trantype: 'PM',
            trancategory: 'T',
            branch: tpLocationCode,
            tpFaDownloadOption: tpFaDownloadOption,
            tpFromFaDownload: tpFromFaDownload,
            tpToFaDownload: tpToFaDownload,

            tpModuleData: tpModuleData,
            tmp_location: tpLocationCode,
            radioType: radioType,
            tpFromSrno: tpFromSrno,
            tpToSrno: tpToSrno,
            tpFromDate: Common.dateYMD(tpFromDate),
            tpToDate: Common.dateYMD(tpToDate),
            tpStatus: tpStatus,
            tmp_type: 'PM',
            fadownloadtype: tpFaDownloadType
        };

        Common.callDownloadApiPost(Common.apiTranModulePayment, "post", [sid, 'faDownload', JSON.stringify(obj)]);
    }

    const handleStatus = (e) => {
        setTPStatus(e.target.value);
        setTPModuleData([]);
    }

    const handleFaDownloadOption = (e) => {
        setTpFaDownloadOption(e.target.value);
        setTPModuleData([]);
    }

    const handleUnlockLevel1 = () => {
        $('.loader').show();

        if ((userEncashmentL1.query !== '1' && tpCategory === 'E') || (userThirdPartyL1.query !== '1' && tpCategory === 'T') || (userFundTransferL1.query !== '1' && tpCategory === 'F')
            || (userExpenseL1.query !== '1' && tpCategory === 'Ex')) {
            $('.loader').hide();
            setMsgText(`You can't unlock this entry`);
            setMsgBox(true);
        } else {
            const obj = {
                remark: 'Unlock level 1',
                activitytype: 'MODIFY',
                subtranno: 1,
                trannumber: srNo,
                trantype: 'PM',
                trancategory: 'T',
                branch: tpLocationCode,

                tmp_srno: srNo,
                tmp_type: 'PM',
                tmp_location: tpLocationCode,
                radioType: radioType,
                tpFromSrno: tpFromSrno,
                tpToSrno: tpToSrno,
                tpFromDate: tpFromDate,
                tpToDate: tpToDate,
                tpStatus: tpStatus
            }
            Common.callApi(Common.apiTranModulePayment, [sid, 'unlockLevelOne', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setTPModuleData(resp)
                setTpParty(null)
                $('.loader').hide();
                setScrMode('');
            })
        }
    }

    const handleUnlockLevel2 = () => {
        $('.loader').show();
        if ((userEncashmentL2.query !== '1' && tpCategory === 'E') || (userThirdPartyL2.query !== '1' && tpCategory === 'T') || (userFundTransferL2.query !== '1' && tpCategory === 'F')
            || (userExpenseL2.query !== '1' && tpCategory === 'Ex')) {
            $('.loader').hide();
            setMsgText(`You can't unlock this entry`);
            setMsgBox(true);
        } else {
            const obj = {
                remark: 'Unlock level 2',
                activitytype: 'MODIFY',
                subtranno: 1,
                trannumber: srNo,
                trantype: 'PM',
                trancategory: 'T',
                branch: tpLocationCode,

                tmp_srno: srNo,
                tmp_type: 'PM',
                tmp_location: tpLocationCode,
                radioType: radioType,
                tpFromSrno: tpFromSrno,
                tpToSrno: tpToSrno,
                tpFromDate: tpFromDate,
                tpToDate: tpToDate,
                tpStatus: tpStatus
            }
            Common.callApi(Common.apiTranModulePayment, [sid, 'unlockLevelTwo', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setTPModuleData(resp)
                $('.loader').hide();
                setScrMode('');
            })
        }
    }

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setAllChecked(isChecked);

        // Update tpModuleData to reflect the "Select All" checkbox change
        const updatedModuleData = tpModuleData.map(item => ({
            ...item,
            ischecked: isChecked ? '1' : '0'
        }));

        setTPModuleData(updatedModuleData);

        // Update tpBankData to include only checked items
        const updatedBankData = isChecked ? updatedModuleData : [];
        setTPBankData(updatedBankData);
    };

    const handleCharges = (e) => {
        let value = e.target.value;
        Common.validateNumValue(value, setTpOtherCharges);

        setTpNetPayable(tpAmount - value - tpTds - tpGst);
    }

    const handleTDS = (e) => {
        let value = e.target.value;
        Common.validateNumValue(value, setTpTds);

        setTpNetPayable(tpAmount - value - tpOtherCharges - tpGst);
    }

    const handleGST = (e) => {
        let value = e.target.value;
        Common.validateNumValue(value, setTpGst);

        setTpNetPayable(tpAmount - value - tpTds - tpOtherCharges);
    }

    const handleLedgerValue = (value) => {
        setTpFaLedgerValue(value);
        // setViewAccount(value);
        const account = value.id.split('^');
        console.log(account);
        // const groupCode = account[1];
        // const accountType = account[4];
        // setMvgType(accountType);
        setTpFaGroup(account[1]);
        setTpFaAccount(account[2]);

    }

    const handleParty = (value) => {
        setTpParty(value);
        const account = value.id.split('^');
        console.log(account);

        setTpPartyGroup(account[1]);
        setTpPartyAccount(account[2]);

    }

    const handleAddFa = () => {
        $('.loader').show();

        if (!tpFaLedgerValue || !tpFaAmount) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!tpFaLedgerValue ? "Ledger." : '');
            msg[i++] = (!tpFaAmount ? "Amount." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (tpFaEntry.length === 4) {
            setMsgText('You can add only four FA entries');
            setMsgBox(true);
        }
        else {
            setTpFaMaxLine(tpFaMaxLine * 1 + 1);

            const obj = {
                remark: 'Fa Entry Added',
                activitytype: 'MODIFY',
                subtranno: 1,
                trannumber: srNo,
                trantype: 'PM',
                trancategory: 'T',
                branch: tpLocationCode,

                srno: srNo,
                type: 'PM',
                line: tpFaMaxLine * 1 + 1,
                location: tpLocationCode,
                tpFaFlag: tpFaFlag,
                tpFaGroup: tpFaGroup,
                tpFaAccount: tpFaAccount,
                tpFaAmount: tpFaAmount
            }
            Common.callApi(Common.apiTranModulePayment, [sid, "addFaEntry", JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setTpFaAmount('');
                setTpFaFlag('C');
                setTpFaLedgerValue(null);
                setTpFaEntry(resp);
                calculateTotal(resp)
                $('.loader').hide();
            })
        }
    }

    const handleDeleteFaEntry = (line, srno, location, type) => {
        $('.loader').show();

        const obj = {

            remark: 'Fa Entry Deleted',
            activitytype: 'MODIFY',
            subtranno: 1,
            trannumber: srNo,
            trantype: 'PM',
            trancategory: 'T',
            branch: tpLocationCode,

            line: line,
            srno: srno,
            location: location,
            type: type
        }

        Common.callApi(Common.apiTranModulePayment, [sid, "deleteFaEntry", JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setTpFaEntry(resp);
            calculateTotal(resp)
            $('.loader').hide();
        })

    }

    const calculateTotal = (list) => {
        let totalDebt = 0;
        let totalCred = 0;
        let i;
        for (i = 0; i < list.length; i++) {
            if (list[i].tmpfa_credit_debit == 'D') {

                totalDebt = totalDebt + list[i].tmpfa_amount * 1;
            } else {

                totalCred += list[i].tmpfa_amount * 1;
            }
        }
        setTotalCredit(totalCred);
        setTotalDebit(totalDebt);
        let totalDiff = totalDebt - totalCred;
        setTotalAmount(totalDiff);
        console.log(totalDiff);
    }

    const handleDownloadModal = () => {
        if (tpBankData.length === 0) {
            setMsgBox(true);
            setMsgText('Please select at least one record');
        } else {
            setShowDownloadModal(true);
        }
    }

    function formatDate(date) {
        // Extract parts of the date
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        // Construct the formatted date string
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    }


    // const enhancedOptions = tpCategoryOptions.slice();
    // if (scrMode === 'Q' && tpCategory === 'CM') {
    //     enhancedOptions.push({ value: 'CM', label: 'Commission' });
    // }

    const handleSubCategory = (value) => {
        setTpSubCategory(value);
    }

    const handleLocationOneAll = (v) => {
        setLocationOneAll(v);
        setTPModuleData([]);
    }



    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h3>Payment Module  <span style={{ color: 'white' }}>{count}</span></h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>

                {
                    scrMode === '' ?
                        <>
                            <Row>
                                {/* <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={tpLocationValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                 */}
                                <Col className='col-md-6 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            {
                                                userRight.add === "1" ?
                                                    <Col>
                                                        <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                                    </Col> : null
                                            }
                                            <Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "St"} onChange={() => setRadioType("St")} type='radio' label="Status" />
                                            </Col>
                                            {
                                                downloadFaRight.query === '1' &&
                                                <Col>
                                                    <Form.Check checked={radioType === "Fa"} onChange={() => setRadioType("Fa")} type='radio' label="FA Download" />
                                                </Col>
                                            }
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'St' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Status<span className="colorThemeRed">*</span></Form.Label>
                                                    <Form.Select value={tpStatus} onChange={(e) => handleStatus(e)} required >
                                                        <option value="N">New</option>
                                                        <option value="PA1">Pending Approval 1</option>
                                                        <option value="PA2">Pending Approval 2</option>
                                                        <option value="PT">Pending Transfer</option>
                                                        <option value="D">Done</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Status<span className="colorThemeRed">*</span></Form.Label>
                                                    <Form.Select value={paymentOption} onChange={(e) => setPaymentOption(e.target.value)} required >
                                                        <option value="A">All</option>
                                                        <option value="O">One</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            {
                                                paymentOption === "O" &&
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Select value={paymentPayType} onChange={(e) => setPaymentPayType(e.target.value)} required >
                                                            <option value="PM">Payment</option>
                                                            <option value="CM">Commission</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            }
                                        </> : null
                                }

                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label><br />
                                                    <DatePicker className="form-control"
                                                        selected={tpDate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setTPDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={tpFromSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setTPFromSrno)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={tpToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setTPToSrno)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            :
                                            radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label><br />
                                                            <DatePicker className="form-control"
                                                                selected={tpFromDate}
                                                                onChange={(date) => setTPFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText='From Date'
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label><br />
                                                            <DatePicker className="form-control"
                                                                selected={tpToDate}
                                                                onChange={(date) => setTPToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText='To Date'
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                :
                                                radioType === 'Fa' ?
                                                    <>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>FA Download<span className="colorThemeRed">*</span></Form.Label>
                                                                <Form.Select value={tpFaDownloadOption} onChange={(e) => handleFaDownloadOption(e)} required >
                                                                    <option value="N">New</option>
                                                                    <option value="P">Period</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                                                <Form.Select value={tpFaDownloadType} onChange={(e) => settpFaDownloadType(e.target.value)} required >
                                                                    <option value="CM">CM</option>
                                                                    <option value="PM">PM</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        {
                                                            downloadFaRight.query === '1' && tpFaDownloadOption === 'P' &&
                                                            <>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label><br />
                                                                        <DatePicker className="form-control"
                                                                            selected={tpFromFaDownload}
                                                                            onChange={(date) => setTpFromFaDownload(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            placeholderText='From Date'
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label><br />
                                                                        <DatePicker className="form-control"
                                                                            selected={tpToFaDownload}
                                                                            onChange={(date) => setTpToFaDownload(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            placeholderText='To Date'
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                        }
                                                    </>
                                                    : null
                                }
                                {
                                    radioType !== "" &&
                                    <Row>
                                        {
                                            radioType === "St" && tpStatus === "PT" ?
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Location One/All<span className="text-danger">*</span></Form.Label>
                                                        <Form.Select value={locationOneAll} onChange={e => handleLocationOneAll(e.target.value)}>
                                                            <option value="O">One</option>
                                                            <option value="A">All</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                : null
                                        }
                                        <Col className='col-md-3 col-6' style={{ display: locationOneAll === "A" && "none" }}>
                                            <Form.Group>
                                                <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={tpLocationValue}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleChangeLocation}
                                                    isDisabled={branchDisable === "true"}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }
                            </Row>&nbsp;

                            <Row>
                                <Col>
                                    {
                                        userRight.add === "1" && radioType === 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.query === "1" &&
                                            (radioType === 'S' || radioType === 'D' || radioType === 'St') ?
                                            < Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => tpList()}>List</Button>
                                            : null
                                    }

                                    {
                                        downloadBankRight.query === "1" && radioType === 'St' && tpStatus === 'PT' && tpModuleData.length > 0 && isDownload === true ?
                                            <>
                                                &nbsp;< Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => setShowDownloadModal(true)}>Download Bank File</Button>
                                            </>
                                            : null
                                    }
                                    {
                                        downloadFaRight.query === "1" && radioType === 'Fa' ?
                                            <>
                                                &nbsp;< Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => downloadFaFile()}>Download FA</Button>
                                            </>
                                            : null
                                    }
                                </Col>
                            </Row>&nbsp;


                            {
                                tpModuleData.length > 0 &&
                                <Row>
                                    <Col>
                                        <Table responsive bordered striped>
                                            <thead>
                                                <tr>

                                                    <th>
                                                        {downloadBankRight.query === '1' && radioType === 'St' && tpStatus === "PT" &&
                                                            <Form.Check
                                                                type='checkbox'
                                                                label=""
                                                                checked={allChecked}
                                                                onChange={handleSelectAll}
                                                            />
                                                        }

                                                    </th>
                                                    <th>Srno</th>
                                                    <th>Ref No</th>
                                                    <th>Location</th>
                                                    <th>Type</th>
                                                    <th>Status</th>
                                                    <th>Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tpModuleData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {downloadBankRight.query === '1' && radioType === 'St' && tpStatus === "PT" ?
                                                                    <>
                                                                        <span style={{ display: 'inline-block' }}>
                                                                            <Form.Check
                                                                                type='checkbox'
                                                                                label=""
                                                                                checked={item.ischecked === '1'}
                                                                                name="ischecked"
                                                                                onChange={(e) => updateCheck(index, e, "ischecked")}
                                                                            />
                                                                        </span>
                                                                        &nbsp;
                                                                    </>
                                                                    : null
                                                                }
                                                                <span
                                                                    className='pointer colorThemeBlue'
                                                                    style={{ display: 'inline-block' }}
                                                                    onClick={() => viewTPData(item.tmp_srno, item.tmp_location, item.tmp_type, item.tmp_request_date)}
                                                                >
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>

                                                            <td>{item.tmp_srno}</td>
                                                            <td>{item.tmp_ref_no}</td>
                                                            <td>{item.entity_name}</td>
                                                            <td>{item.tmp_type}</td>
                                                            <td> {item.tmp_status === 'PA1' ? 'Pending Approval 1' : item.tmp_status === 'PA2' ? 'Pending Approval 2' :
                                                                item.tmp_status === 'PT' ? 'Pending Transfer' : item.tmp_status === 'D' ? 'Done' : 'New'}</td>
                                                            <td>{item.tmp_remark}</td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            }

                        </> : scrMode != "" ? <>

                            <Row>
                                <Col>
                                    < Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => setScrMode('')}>Back</Button>{' '}
                                    {/* < Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => handleSave()}>Save</Button> */}

                                    {
                                        (userRight.query === "1" && scrMode === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                btnActivityLog()}>Activity Log</Button>&nbsp;
                                        </> : null
                                    }
                                    {/* {
                                        (userRight.edit === "1" && scrMode === 'Q') ?
                                            //  resStatus === 'N' && scrMode === 'Q') ? 
                                            <>
                                                <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => setScrMode('E')} >Edit</Button>&nbsp;
                                            </> : null
                                    } */}
                                    {
                                        (userRight.add === "1" && resStatus === 'N' && scrMode !== '') ?
                                            // || (userRight.add === "1" && scrMode === 'A') 
                                            <>   < Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => handleSave()}>Save</Button>&nbsp;
                                            </>
                                            : null
                                    }


                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label><b>Location :</b> {tpLocationName}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label><b>Status :</b> {resStatus === 'PA1' ? 'Pending Approval 1' : resStatus === 'PA2' ? 'Pending Approval 2' :
                                        resStatus === 'PT' ? 'Pending Transfer' : resStatus === 'D' ? 'Done' : 'New'}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label><b>Date :</b> {resDate}</Form.Label></Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label><b>Maker :</b> {tpMaker}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label><b>Checker 1 :</b> {tpChecker1}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label><b>Checker 2 :</b> {tpChecker2}</Form.Label>
                                </Col>
                            </Row>

                            <Row>&nbsp;</Row>

                            <Row>
                                {/* <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Category<span className="colorThemeRed">*</span></Form.Label>
                                        <Form.Select value={tpCategory} onChange={(e) => setTPCategory(e.target.value)}
                                            disabled={!(resStatus === 'N' && userRight.add === '1')}
                                            required >
                                            <option value="E">Encashment</option>
                                            <option value="T">Third Party Payment</option>
                                            <option value="F">Fund Transfer</option>
                                            <option value="Ex">Expense</option>
                                            {
                                                scrMode === 'Q' && tpCategory === 'CM' &&
                                                <option value="CM">Commission</option>
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col> */}
                                {/* <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Category<span className="colorThemeRed">*</span></Form.Label>
                                        <Form.Select value={tpCategory} onChange={(e) => setTPCategory(e)} options={tpCategoryOptions}></Form.Select>

                                    </Form.Group>
                                </Col> */}
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Category<span className="colorThemeRed">*</span></Form.Label>
                                        <Form.Select value={tpCategory} onChange={(e) => setTPCategory(e.target.value)}
                                            disabled={!(resStatus === 'N' && userRight.add === '1')}>
                                            {tpCategoryOptions.map((item, index) => (
                                                <option key={index} value={item.tpc_value}>
                                                    {item.tpc_name}
                                                </option>
                                            ))}
                                            {
                                                scrMode === 'Q' && tpCategory === 'CM' &&
                                                <option value={tpCategory}>Commission</option>
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                {tpCategory === 'E' ?
                                    <>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Encashment Type<span className="text-danger">*</span></Form.Label>
                                                <Form.Select value={tpEncashmentType} onChange={(e) => setTPEncashmentType(e.target.value)}
                                                    // disabled={scrMode === 'Q'}
                                                    disabled={!(resStatus === 'N' && userRight.add === '1')} required >
                                                    <option value="PP">PP</option>
                                                    {/* <option value="SP">SP</option>
                                                    <option value="SB">SB</option> */}
                                                    <option value="PB">PB</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Encashment Number<span className="text-danger">*</span></Form.Label>
                                                <Form.Control value={tpEncashmentNum}
                                                    maxLength={20}
                                                    onChange={(e) => Common.validateNumValue(e.target.value, setTPEncashmentNum)}
                                                    placeholder="Encashment Number"
                                                    // disabled={scrMode === 'Q'}
                                                    disabled={!(resStatus === 'N' && userRight.add === '1')} />
                                            </Form.Group>
                                        </Col>
                                    </>
                                    : tpCategory === 'T' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Depositor Name<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={tpDepositorName}
                                                        maxLength={200}
                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setTPDepositorName)}
                                                        placeholder="Depositor Name"
                                                        // disabled={scrMode === 'Q'}
                                                        disabled={!(resStatus === 'N' && userRight.add === '1')} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Transaction Description<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={tpTransactionDesc}
                                                        maxLength={50}
                                                        onChange={(e) => Common.validateAlpNumValue(e.target.value, setTPTransactionDesc)}
                                                        placeholder="Transaction Description"
                                                        // disabled={scrMode === 'Q'}
                                                        disabled={!(resStatus === 'N' && userRight.add === '1')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Realationship Manager<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={tpRealationshipMang}
                                                        maxLength={20}
                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setTPRealationshipMang)}
                                                        placeholder="Realationship Manager"
                                                        // disabled={scrMode === 'Q'}
                                                        disabled={!(resStatus === 'N' && userRight.add === '1')}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Document Collected<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={tpDocumentDesc}
                                                        maxLength={50}
                                                        onChange={(e) => Common.validateAlpNumValue(e.target.value, setTPDocumentDesc)}
                                                        placeholder="Document Collected"
                                                        // disabled={scrMode === 'Q'}
                                                        disabled={!(resStatus === 'N' && userRight.add === '1')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>BK Number{resStatus === "PA2" && <span className="text-danger">*</span>}</Form.Label>
                                                    <Form.Control value={tpBKNumber}
                                                        maxLength={50}
                                                        onChange={(e) => Common.validateAlpNumValue(e.target.value, setTPBKNumber)}
                                                        placeholder="BK Number"
                                                        // disabled={scrMode === 'Q'}
                                                        disabled={!(resStatus === 'PA2' && userApproveL2.query === '1')}

                                                    />
                                                </Form.Group>
                                            </Col>
                                        </> : tpCategory === "Ex" ?
                                            <>
                                                <Col className='col-md-3'>
                                                    <Form.Group>
                                                        <Form.Label>Sub-category</Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={tpSubCategory}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={(search) => Picklist.fetchExpenseSubPurposeDetailsPicklist(search)} // location = 1 , 2
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={(e) => handleSubCategory(e)}
                                                            // isDisabled={scrMode === 'Q'}
                                                            isDisabled={!(resStatus === 'N' && userRight.add === '1')}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : null
                                }
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Invoice Amount<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={tpAmount}
                                            maxLength={20}
                                            onChange={(e) => Common.validateNumValue(e.target.value, setTPAmount)}
                                            placeholder="Amount"
                                            // disabled={scrMode === 'Q'}
                                            disabled={!(resStatus === 'N' && userRight.add === '1')}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    tpCategory === 'F' &&
                                    <>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>From Location<span className="colorThemeRed">*</span></Form.Label>
                                                <Form.Control value={tpLocationName}
                                                    disabled={true}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>From Bank<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={tpBank}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchBankDetailsPicklist(search, tpLocationCode, "")} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={(e) => handleChangeBank(e)}
                                                    // isDisabled={scrMode === 'Q'}
                                                    isDisabled={!(resStatus === 'N' && userRight.add === '1')}

                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>

                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>To Location<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={tpToLocationValue}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleChangeToLocation}
                                                    isDisabled={!(resStatus === 'N' && userRight.add === '1')}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>To Bank<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={tpToBank}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchBankDetailsPicklist(search, tpToLocationCode, "")} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={(e) => handleChangeToBank(e)}
                                                    // isDisabled={scrMode === 'Q'} 
                                                    isDisabled={!(resStatus === 'N' && userRight.add === '1')}                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Reference No.<span className="text-danger">*</span></Form.Label>
                                                <Form.Control value={tpRef}
                                                    maxLength={100}
                                                    onChange={(e) => Common.validateAlpNumSplValue(e.target.value, setTpRef)}
                                                    placeholder="Reference No."
                                                    // disabled={scrMode === 'Q'}
                                                    disabled={!(resStatus === 'N' && userRight.add === '1')}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                }
                            </Row>
                            {
                                tpCategory !== 'F' &&
                                <Row>
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>Account Name<span className="text-danger">*</span></Form.Label>
                                            <Form.Control value={tpAccountName}
                                                maxLength={255}
                                                onChange={(e) => Common.validateAlpValue(e.target.value, setTPAccountName)}
                                                placeholder="Account Name"
                                                // disabled={scrMode === 'Q'}
                                                disabled={!(resStatus === 'N' && userRight.add === '1')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>Account Number<span className="text-danger">*</span></Form.Label>
                                            <Form.Control value={tpAccountNum}
                                                maxLength={20}
                                                onChange={(e) => Common.validateNumValue(e.target.value, setTPAccountNum)}
                                                placeholder="Account Number"
                                                disabled={!(resStatus === 'N' && userRight.add === '1')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>IFSC<span className="text-danger">*</span></Form.Label>
                                            <Form.Control value={tpIfsc}
                                                maxLength={11}
                                                onChange={(e) => Common.validateAlpNumValue(e.target.value, setTPIfsc)}
                                                placeholder="IFSC"
                                                disabled={!(resStatus === 'N' && userRight.add === '1')}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                            <hr />
                            <Row>
                                <Col>
                                    <h4>Documents</h4>
                                </Col>
                            </Row>
                            {
                                scrMode === 'A' || scrMode === 'E' ?
                                    <Row>
                                        <Col className='col-md-3 col-6' >
                                            <Form.Group controlId="uploadPaymentModuleDoc">
                                                <Form.Label>Upload Document{tpCategory != 'F' && <span className="text-danger">*</span>}</Form.Label>
                                                <Form.Control type="file" value={tpUploadDoc} disabled={scrMode === 'Q'}
                                                    onChange={e => setTPUploadDoc(e.target.value)} />&nbsp;
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Document Description{tpCategory != 'F' && <span className="text-danger">*</span>}</Form.Label>
                                                <Form.Control value={tpUploadDocDesc}
                                                    maxLength={50}
                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setTPUploadDocDesc)}
                                                    placeholder="Document Description"
                                                    disabled={scrMode === 'Q'}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            < Button style={{ margin: '33px' }} variant='outline-success' className='buttonStyle' size='sm' onClick={() => handleAddDoc()}>Add</Button>
                                        </Col>
                                        <Col>&nbsp;</Col>
                                    </Row> : null
                            }


                            {
                                uploadedDoc.length > 0 &&
                                <Row>
                                    <Col>
                                        <Table responsive bordered striped>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Ext</th>
                                                    <th>Document Name</th>
                                                    <th>Document Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    uploadedDoc.map((item, index) => (
                                                        <tr >
                                                            <td className="textCenter " >
                                                                {
                                                                    scrMode === 'A' || scrMode === 'E' ?
                                                                        <>
                                                                            <span className="handCursor colorThemeBlue"
                                                                                onClick={() => handleDeleteDoc(item.tmd_line_no, item.tmd_srno, item.tmd_type, item.tmd_location)}>
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </span>&nbsp;
                                                                        </> : null
                                                                }
                                                                {
                                                                    item.tmd_doc_ext === "jpg" || item.tmd_doc_ext === "jpeg" || item.tmd_doc_ext === "png" || item.tmd_doc_ext === "pdf" || item.tmd_doc_ext === "bmp" || item.tmd_doc_ext === "gif" ?
                                                                        <>
                                                                            <span className='pointer colorThemeBlue' onClick={() => handleViewDoc(item.tmd_line_no, item.tmd_doc_ext, item.tmd_srno, item.tmd_type, item.tmd_location)}>
                                                                                <FontAwesomeIcon icon={faEye} />
                                                                            </span>&nbsp;
                                                                        </> : null
                                                                }
                                                                <span className='pointer colorThemeBlue' onClick={() => btnDocumentDownload(item.tmd_line_no, item.tmd_srno, item.tmd_type, item.tmd_location)}>
                                                                    <FontAwesomeIcon icon={faFileDownload} />
                                                                </span>
                                                            </td>
                                                            <td>{item.tmd_doc_ext}</td>
                                                            <td>{item.tmd_doc_name}</td>
                                                            <td>{item.tmd_doc_desc}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            }
                            {/* </>
                            } */}
                        </> : null
                }


                {
                    //userApproveL1.query === '1' || 
                    userApproveL2.query === '1' && scrMode === 'Q' &&
                    <>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>GST<span className="text-danger">*</span></Form.Label>
                                    <Form.Control value={tpGst}
                                        maxLength={20}
                                        // onChange={(e) => Common.validateNumValue(e.target.value, setTpGst)}
                                        onChange={(e) => handleGST(e)}
                                        placeholder="GST"
                                        disabled={!(userApproveL2.query && resStatus === 'PA2')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>TDS<span className="text-danger">*</span></Form.Label>
                                    <Form.Control value={tpTds}
                                        maxLength={20}
                                        // onChange={(e) => Common.validateNumValue(e.target.value, setTpTds)}
                                        onChange={(e) => handleTDS(e)}
                                        placeholder="TDS"
                                        disabled={!(userApproveL2.query && resStatus === 'PA2')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Charges<span className="text-danger">*</span></Form.Label>
                                    <Form.Control value={tpOtherCharges}
                                        maxLength={20}
                                        // onChange={(e) => Common.validateNumValue(e.target.value, setTpOtherCharges)}
                                        onChange={(e) => handleCharges(e)}
                                        placeholder="Charges"
                                        disabled={!(userApproveL2.query && resStatus === 'PA2')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Net Payable<span className="text-danger">*</span></Form.Label>
                                    <Form.Control value={tpNetPayable}
                                        maxLength={20}
                                        // onChange={(e) => Common.validateNumValue(e.target.value, setTpNetPayable)}
                                        placeholder="Amount"
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                            {
                                userApproveL2.query === '1' && resStatus !== 'N' &&
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Party<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={tpParty}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, tpLocationCode)} // location = 1 , 2
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleParty}
                                            isDisabled={!(userApproveL2.query === '1' && resStatus === 'PA2') || tpCategory === 'F'}
                                        // isDisabled={(scrMode === 'Q' && tpParty !== '' && tpApprovalOne === 'Y') || userApproveL1.query !== '1'}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                            }
                        </Row>

                        {
                            userApproveL2.query === '1' && resStatus === 'PA2' ?
                                <>
                                    <hr />
                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Cr/Dr<span className="text-danger">*</span></Form.Label>
                                                <Form.Select value={tpFaFlag} onChange={(e) => setTpFaFlag(e.target.value)}
                                                    required >
                                                    <option value="C">Credit</option>
                                                    <option value="D">Debit</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Ledger<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={tpFaLedgerValue}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, tpLocationCode)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleLedgerValue}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Amount<span className="text-danger">*</span></Form.Label>
                                                <Form.Control value={tpFaAmount}
                                                    maxLength={20}
                                                    onChange={(e) => Common.validateNumValue(e.target.value, setTpFaAmount)}
                                                    placeholder="Amount"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            < Button style={{ margin: '33px' }} variant='outline-success' className='buttonStyle' size='sm' onClick={() => handleAddFa()}>Add</Button>
                                        </Col>
                                    </Row>
                                </> : null
                        }
                        {
                            tpFaEntry.length > 0 &&
                            <Row>
                                <Col>
                                    <Table responsive bordered striped>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Ledger</th>
                                                <th>Credit</th>
                                                <th>Debit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tpFaEntry.map((item, index) => (
                                                    <tr >
                                                        <td>
                                                            {
                                                                userApproveL2.query === "1" && resStatus === "PA2" &&
                                                                <span className="handCursor colorThemeBlue"
                                                                    onClick={() => handleDeleteFaEntry(item.tmpfa_line_no, item.tmpfa_srno, item.tmpfa_location, item.tmpfa_type)}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </span>
                                                            }
                                                        </td>
                                                        <td>{item.mvli_ledger_name}</td>
                                                        <td>{item.tmpfa_credit_debit === 'C' && item.tmpfa_amount}</td>
                                                        <td>{item.tmpfa_credit_debit === 'D' && item.tmpfa_amount}</td>
                                                    </tr>
                                                ))
                                            }
                                            <tr>

                                                <td style={{ textAlign: 'left', color: 'red' }} colSpan={2}>
                                                    {
                                                        totalAmount !== 0 &&
                                                        <b>Difference : {totalAmount > 0 ? totalAmount + ' Dr' : -1 * totalAmount + ' Cr'}</b>
                                                    }
                                                </td>
                                                <td colSpan={1}><b>{totalCredit}</b></td>
                                                <td colSpan={1}><b>{totalDebit}</b></td>

                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        }
                    </>
                }

                {scrMode !== '' &&
                    <>
                        <hr />
                        <Row>
                            <Col className='col-md-6 col-6'>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Narration{tpCategory != 'F' && <span className="text-danger">*</span>}</Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                        maxLength={500}
                                        placeholder="Narration"
                                        value={tpRemark}
                                        onChange={(e) => Common.validateAlpNumValue(e.target.value, setTPRemark)}
                                        disabled={scrMode === 'Q'} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>&nbsp;</Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <h4>Comments</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control value={tpComment}
                                    // maxLength={20}
                                    onChange={(e) => Common.validateAlpNumValue(e.target.value, setTPComment)}
                                    placeholder="Add Comment"
                                />
                            </Col>
                            <Col className='col-md-3 col-6'>
                                < Button style={{ margin: '33px' }} variant='outline-success' className='buttonStyle' size='sm' onClick={() => handleAddComment()}>Add</Button>
                            </Col>
                            <Col>&nbsp;</Col>
                            <Col>&nbsp;</Col>
                        </Row>

                        {
                            tpCommentData.length > 0 &&
                            <Row>
                                <Col>
                                    <Table responsive bordered striped>
                                        <thead>
                                            <tr>
                                                {/* <th></th> */}
                                                {/* <th>Srno</th>
                                                <th>Line no</th> */}
                                                {/* <th>Location</th>
                                                <th>Type</th> */}
                                                <th>User Id</th>
                                                <th>Comment</th>
                                                <th>Timestamp</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tpCommentData.map((item, index) => (
                                                    <tr >
                                                        {/* <td>
                                        {
                                            scrMode === 'A' &&
                                            <span className="handCursor colorThemeBlue"
                                                onClick={() => handleDeleteComment(item.tc_line_no)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </span>
                                        }
                                    </td> */}
                                                        {/* <td>{item.tc_srno}</td>
                                <td>{item.tc_line_no}</td> */}
                                                        {/* <td>{item.entity_name}</td>
                        <td>{item.tc_type}</td> */}
                                                        <td>{item.um_user_name}</td>
                                                        <td>{item.tc_comment}</td>
                                                        <td>{item.tc_timestamp}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        }


                        <hr />
                    </>
                }

                {scrMode === 'Q' &&
                    <>
                        {
                            (userApproveL1.query || userEncashmentL1.query || userThirdPartyL1.query || userFundTransferL1.query || userExpenseL1.query)
                            && tpApprovalOne === 'N' && resStatus === 'PA1' &&
                            <>
                                <Row>
                                    <Col>
                                        <Button variant="outline-success" onClick={() => handleApprovalOne()}>
                                            Approval Level 1
                                        </Button>&nbsp;
                                        <Button variant="outline-danger" onClick={() => handleUnlockLevel1()}  >
                                            Unlock
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {
                            (userApproveL2.query || userEncashmentL2.query || userThirdPartyL2.query || userFundTransferL2.query || userExpenseL2.query)
                            && tpApprovalOne === 'Y' && tpApprovalTwo === 'N' && resStatus === 'PA2' &&
                            <>
                                <Row>
                                    <Col>
                                        <Button variant="outline-success" onClick={() => handleApprovalTwo()}>
                                            Approval Level 2
                                        </Button>&nbsp;
                                        <Button variant="outline-danger" onClick={() => handleUnlockLevel2()} >
                                            Unlock
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row>
                            <Col>
                            </Col>
                        </Row>
                    </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>

            <Modal
                show={showDocument}
                onHide={() => setShowDocument(false)}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {documentDescToView + " : " + documentNameToView}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={documentToView} type={documentTypeToView} style={{ minHeight: "100vh", minWidth: "100%" }} ></embed>
                    {
                        /*
                        type="image/png" type="application/pdf"
                        width="100%" height="100%"
                        
                        <object data={documentToView} type={documentTypeToView} class="internal">
     
                        </object>
                        */
                    }
                </Modal.Body>
            </Modal>

            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />

            <div>
                <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} animation={false} centered>
                    <Modal.Body>
                        {
                            scrMode === 'E' ?
                                <>
                                    <Form.Group>
                                        <Form.Label>Reason<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Reason" maxLength={499}
                                            value={acRemark}
                                            onChange={(e) => Common.validateAlpNumValue(e.target.value, setAcRemark)}
                                            autoComplete="off"
                                            required />
                                    </Form.Group>
                                </> :
                                null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => btnSaveModal()}>
                            Save
                        </Button>&nbsp;
                        <Button variant="outline-success" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>


            <Modal size='lg' show={showDownloadModal} onHide={() => setShowDownloadModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Bank Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>From Bank<span className="text-danger">*</span></Form.Label>
                                <AsyncSelect
                                    cacheOptions={false}
                                    defaultOptions={false}
                                    value={bankDetail}
                                    getOptionLabel={e => e.label + ' '}
                                    getOptionValue={e => e.id}
                                    loadOptions={(search) => Picklist.fetchBankDetailsPicklist(search, tpLocationCode, "AXIS")} // location = 1 , 2
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={(e) => handleChangeBankDetail(e)}
                                >
                                </AsyncSelect>
                            </Form.Group>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDownloadModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={downloadBankFile}>
                        Download Bank Files
                    </Button>
                </Modal.Footer>
            </Modal>


            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
        </div >
    )
}

export default Tran_module_payment;
