import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Tab, Table, Tabs, Badge } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import FaView from '../FaView';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from "../Picklist";
import Tran_data_unlock_request from '../Tran_data_unlock_request';
import Tran_commission_view from './Tran_commission_view';

function Tran_pb() {
    const productRef = useRef();
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');

    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const [key, setKey] = useState("INFO");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [radioType, setRadioType] = useState("N");
    const [scrMode, setScrMode] = useState("");

    const [hdrSrno, setHdrSrno] = useState(0);
    const [pbList, setPBList] = useState([]);
    const [userRight, setUserRight] = useState([]);
    const [isdOptions, setIsdOptions] = useState([]);
    const [residentOptions, setResidentOptions] = useState([]);

    const [pbLocation, setPBLocation] = useState(null);
    const [pbLocationValue, setPBLocationValue] = useState(0);
    const [pbDealer, setPBDealer] = useState(null);
    const [pbFromDate, setPBFromDate] = useState(new Date());
    const [pbToDate, setPBToDate] = useState(new Date());
    const [pbNewDate, setPBNewDate] = useState(new Date());
    const [pbSrnoFrom, setPBSrnoFrom] = useState("");
    const [pbSrnoTo, setPBSrnoTo] = useState("");
    const [pbStateValue, setPBStateValue] = useState(0);
    const [pbStateName, setPBStateName] = useState("");
    const [pbStateType, setPBStateType] = useState(0);

    const [pOtherCharges, setPOtherCharges] = useState(0);
    const [pTotalAmount, setPTotalAmount] = useState(0);
    const [pCgst, setPCgst] = useState(0);
    const [pSgst, setPSgst] = useState(0);
    const [pIgst, setPIgst] = useState(0);
    const [pUgst, setPUgst] = useState(0);
    const [pForexTotal, setPForexTotal] = useState(0);
    const [pRoundOff, setPRoundOff] = useState(0);
    const [pTaxableVal, setPTaxableVal] = useState(0);

    const [pVtm, setPVtm] = useState(null);
    const [pProductList, setPProductList] = useState([]);
    const [pProduct, setPProduct] = useState("CN");
    const [pIsd, setPIsd] = useState(null);
    const [pDenomination, setPDenomination] = useState("");
    const [pQuantity, setPQuantity] = useState("");
    const [commission, setCommission] = useState("");
    const [pIssuer, setPIssuer] = useState(null);
    const [prPrefix, setPrPrefix] = useState("");
    const [prFrom, setPrFrom] = useState("");
    const [prTo, setPrTo] = useState("");
    const [pVtmCardNo, setPVtmCardNo] = useState("");
    const [pAgent, setPAgent] = useState(null);
    const [isCommission, setIsCommission] = useState("0");
    const [commType, setCommType] = useState("");
    const [commValue, setCommValue] = useState("");
    const [pRate, setPRate] = useState("");
    const [counter, setCounter] = useState(0);
    const [productLineNo, setProductLineNo] = useState(0);
    const [pTravellerName, setPTravellerName] = useState("");
    const [pIdType, setPIdType] = useState("0");
    const [pIdNumber, setPIdNumber] = useState("");
    const [maxPRate, setMaxPRate] = useState(0);
    const [minPRate, setMinPRate] = useState(0);

    const [nDealer, setNDealer] = useState(null);
    const [stateOptions, setStateOptions] = useState([]);
    const [nState, setNState] = useState({ value: 0, label: "Select" });
    const [nEmployeeCode, setNEmployeeCode] = useState("");
    const [nMode, setNMode] = useState("");
    const [disableOtherCharge, setDisableOtherCharge] = useState(true);
    const [randomString, setRandomString] = useState("");

    const [pdAccount, setPdAccount] = useState(null);
    const [pdPaymentType, setPdPaymentType] = useState("");
    const [pdAmount, setPdAmount] = useState("");
    const [pdAccountValue, setPdAccountValue] = useState("");
    const [pdChequeNum, setPdChequeNum] = useState("");
    const [paymentData, setPaymentData] = useState([]);
    const [pIsdId, setPIsdId] = useState("");
    const [ldgSrchType, setLdgSrchType] = useState("");
    const [paymentLineNo, setPaymentLineNo] = useState(0);

    const [chequeValue, setChequeValue] = useState(null);
    const [pdGroupValue, setPdGroupValue] = useState(0);

    const [hdrDate, setHdrDate] = useState(null);
    const [hdrLocation, setHdrLocation] = useState("");

    const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });
    const [showFaView, setShowFaView] = useState(false);

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();
    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });
    const [usdRate, setUsdRate] = useState(0);
    const [disablePaymentAccount, setDisablePaymentAccount] = useState(false);

    const [showCommissionDetail, setShowCommissionDetail] = useState(false);
    const [tranAuditTrail, setTranAuditTrail] = useState([]);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);

    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setPBLocation(response);
            response != null && handleLocationChange(response);
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PB'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
        Common.callApi(Common.apiIsd, [sid, "ddlistjsonselect", 'CN'], result => {
            setIsdOptions(JSON.parse(result));
        });
        Common.callApi(Common.apiResidentialStatus, [sid, "listResidential"], result => {
            setResidentOptions(JSON.parse(result));
        });
        Common.callApi(Common.apiState, [sid, "getState", 1], result => {
            setStateOptions(JSON.parse(result));
        });
    }, []);

    const listPBdata = () => {

        var msg = [], i = 0;
        $(".loader").show();
        if (pbLocation === null || (radioType === 'S' && !pbSrnoFrom) || (radioType === 'S' && !pbSrnoTo) || (radioType === 'D' && !pbFromDate)
            || (radioType === 'D' && !pbToDate) || (radioType === 'I' && pbDealer === null) || (radioType === 'I' && !pbFromDate) || (radioType === 'I' && !pbToDate)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pbLocation === null ? " Location." : '');
            msg[i++] = (radioType === 'S' && !pbSrnoFrom ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !pbSrnoTo ? " To Srno." : '');
            msg[i++] = (radioType === 'D' && !pbFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !pbToDate ? " To Date." : '');
            msg[i++] = (radioType === 'I' && pbDealer === null ? " Dealer." : '');
            msg[i++] = (radioType === 'I' && !pbFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'I' && !pbToDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                radioType: radioType,
                pbLocation: pbLocationValue,
                pbDealer: pbDealer,
                pbNewDate: Common.dateYMD(pbNewDate),
                pbSrnoTo: pbSrnoTo,
                pbSrnoFrom: pbSrnoFrom,
                pbFromDate: Common.dateYMD(pbFromDate),
                pbToDate: Common.dateYMD(pbToDate)
            }
            Common.callApi(Common.apiTranPB, [sid, "pblist", JSON.stringify(obj)], result => {
                console.log(result);
                $(".loader").hide();
                setPBList(JSON.parse(result));
            });
        }
    }

    const handleLocationChange = (value) => {
        setPBLocation(value);
        var id = value.id;
        var location = id.split("^");
        setPBLocationValue(location[1]);
        setPBStateValue(location[3]);
        setPBStateType(location[4]);
        setPBStateName(location[5]);

        setHdrLocation(location[2]);
        sessionStorage.activeBranch = location[1];
    }

    const handleDealerChange = (value) => {
        setPBDealer(value);
    }

    const addNewForm = () => {
        var d = Common.dateYMD(pbNewDate);
        var ddmy = Common.dateDMY(pbNewDate);
        var today = Common.dateYMD(new Date());
        if (pbLocation == null) {
            setMsgBox(true);
            setMsgText("Please select location");
        } else if (d > finEndDate || d < finStartDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else if (d > today) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0011"));
        } else {
            setHdrDate(ddmy);

            setScrMode("A");
            setKey("INFO");
            setHdrSrno(0);
            setNDealer(null);
            setNEmployeeCode("");
            if (pbStateValue * 1 > 0 && pbStateValue != null) setNState({ value: pbStateValue, label: pbStateName });
            else setNState({ value: 0, label: "Select" });

            setPProduct("CN");
            setPIsd(null);
            setPIsdId("");
            setPDenomination("");
            setPQuantity("");
            setPRate("");
            setPTravellerName("");
            setPIdNumber("");
            setPIdType("");
            setCommType("");
            setCommValue("");
            setCommission("");
            setIsCommission("0");
            setPForexTotal(0);
            setPTotalAmount(0);
            setPCgst(0);
            setPIgst(0);
            setPUgst(0);
            setPSgst(0);
            setPRoundOff(0);
            setPOtherCharges(0);
            setPTaxableVal(0);

            setPProductList([]);
            setPaymentData([]);
            setProductLineNo(0);
            setPaymentLineNo(0);
            setPVtm(null);
        }
    }

    const handleTabChange = (k) => {
        setKey(k);
        if (k === "PAYMENT") {
            const amt = paymentData.reduce((total, payment) => total + payment.tp_amount * 1, 0);
            setPdAmount(1 * pTotalAmount - amt);
        }
    }

    const handleNewDealerChange = (value) => {
        setNDealer(value);
        if (pdPaymentType === "RC" || pdPaymentType === "PB")
            setPdAccount(value);
    }

    const otherChargeChange = (v) => {
        const obj = {
            tranType: "PB",
            type: "PURCHASE",
            uniquekey: Common.getRandomString(15),
            nState: nState.value,
            pOtherCharges: v === "" ? 0 : v,
            ppStateType: pbStateType,
            ppStateValue: pbStateValue
        }
        Common.validateDecValue(v, setPOtherCharges);
        calcInvoice(pProductList, obj);
    }

    const handleAgentChange = (value) => {
        setPAgent(value);
    }

    const handleProductChange = (v) => {
        setPProduct(v);
        setPIsd(null);
        setPVtm(null);
        setPDenomination("");
        setPRate("");
        setPrFrom("");
        setPVtmCardNo("");
        if (v === "VTM") {
            setPQuantity("1");
        }
        Common.callApi(Common.apiIsd, [sid, "ddlistjsonselect", v], result => {
            setIsdOptions(JSON.parse(result));
        });
    }

    const handleChangeIsd = (value) => {
        setPIsd(value);
        let a = value.id.split("^");
        setPIsdId(a[1]);
        setPAgent(null);
        setCommType("");
        setCommValue("");
        const obj = {
            isd: a[1],
            location: pbLocationValue,
            product: pProduct
        }
        Common.callApi(Common.apiMasterIBR, [sid, "getrate", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPRate(resp.buyrate * 1);
            setMaxPRate(resp.maxbuyrate * 1);
            setMinPRate(resp.minbuyrate * 1);
            setUsdRate(resp.usdratebuy * 1);
        });
    }

    const handleDenoChange = (v) => {
        Common.validateDecValue(v, setPDenomination);
        setCommValue(calcCommission(commission, commType, pQuantity, v, pRate));
    }

    const handleQuantityChange = (v) => {
        Common.validateNumValue(v, setPQuantity);
        if (pProduct === "VTM" || pProduct === "TC") {
            setPrTo((v * 1 + prFrom * 1) - 1);
        }
        setCommValue(calcCommission(commission, commType, v, pDenomination, pRate));
    }

    const handleRateChange = (v) => {
        Common.validateDecValue(v, setPRate);
        setCommValue(calcCommission(commission, commType, pQuantity, pDenomination, v));
    }

    const handleFromChange = (v) => {
        Common.validateNumValue(v, setPrFrom);
        setPrTo((v * 1 + pQuantity * 1) - 1);
        if (pProduct === "VTM") {
            setPrTo(v);
        }
    }

    const commissionTypeChange = (v) => {
        setCommType(v);
        setCommission("");
        setCommValue("");
    }

    const calcCommission = (value, type, quantity, deno, rate) => {
        var comm = 0;
        if (type === "P") {
            comm = (value * quantity * deno) / 100;
        } else if (type === "R") {
            comm = (quantity * deno * rate) * (value / 100);
        } else {
            comm = value;
        }
        return comm.toFixed(2);
    }

    const handleCommissionCalc = (v) => {
        Common.validateDecValue(v, setCommission);
        setCommValue(calcCommission(v * 1, commType, pQuantity, pDenomination, pRate));
    }

    const addProduct = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if ((pProduct === "CN" || pProduct === "TC") && pIsd == null || pDenomination === "" || pQuantity === "" || pRate === "") {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pIsd == null ? "Please select Isd." : '');
            msg[i++] = (pDenomination === '' ? "Please fill Denomination." : '');
            msg[i++] = (pQuantity == '' ? "Please fill Quantity." : '');
            msg[i++] = (pRate == '' ? "Please fill Rate." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if ((pProduct === "TC" || pProduct === "VTM") && (prFrom == "" || prTo == "" || pIssuer == null)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pIssuer == null ? "Please select Issuer." : '');
            msg[i++] = (prFrom == '' ? "Please fill From." : '');
            msg[i++] = (prTo == '' ? "Please fill To." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if ((pProduct === "TC" || pProduct === "VTM") && (prTo * 1 - prFrom * 1 > 100)) {
            $(".loader").hide();
            setMsgText("'To' must be less than 100.");
            setMsgBox(true);
        } else if (isCommission === "1" && (commType === "" || pAgent === null || commValue === "")) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pAgent == null ? "Agent." : '');
            msg[i++] = (commType == '' ? "Commission type." : '');
            msg[i++] = (commValue == '' ? "Commission value." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (maxPRate != 0 && minPRate != 0 && (pRate > maxPRate || pRate < minPRate)) {
            $(".loader").hide();
            msg[i++] = (pRate > maxPRate ? "Maximum rate is " + maxPRate : '');
            msg[i++] = (pRate < minPRate ? "Maximum rate is " + minPRate : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            $(".loader").hide();
            var isd = pIsd.id.split("^");
            var agentgrp = 0;
            var agentaccnt = 0;
            var agentname = "";

            var issgrp = 0;
            var issaccnt = 0;
            if (pAgent !== null) {
                var agent = pAgent.id.split("^");
                agentgrp = agent[1];
                agentaccnt = agent[2];
                agentname = agent[5];
            }
            if (pIssuer !== null) {
                var issuer = pIssuer.id.split("^");
                issgrp = issuer[1];
                issaccnt = issuer[2];
            }
            var usdAmt = 0;
            if (usdRate != "" && usdRate != 0) {
                usdAmt = (pRate * pQuantity * pDenomination) / usdRate;
            }
            const obj = {
                tranType: "PB",
                type: "PURCHASE",
                uniquekey: Common.getRandomString(15),
                prd_line_no: 1 * productLineNo + 1,
                prd_location: pbLocationValue,
                prd_type: 'PB',
                prd_srno: hdrSrno,
                prd_isd: isd[1],
                prd_date: Common.dateYMD(pbNewDate),
                prd_product: pProduct,
                prd_deno: pDenomination,
                prd_qty: pQuantity,
                prd_rate: pRate,
                prd_amount: pRate * pQuantity * pDenomination,
                prd_from: prFrom,
                prd_prefix: prPrefix,
                prd_to: prTo,
                prd_group: issgrp,
                prd_account: issaccnt,
                prd_agent_group: agentgrp,
                prd_agent_ledger: agentaccnt,
                prd_card_no: pVtmCardNo,
                isd_name: isd[2],
                randomString: randomString,
                prd_commission_type: commType,
                prd_commission_value: commValue == "" ? 0 : commValue,
                prd_commission: commission == "" ? 0 : commission,
                //nCorporate: nCorporate,
                nState: nState.value,
                pOtherCharges: pOtherCharges === "" ? 0 : pOtherCharges,
                ppStateType: pbStateType,
                ppStateValue: pbStateValue,
                scrMode: scrMode,
                prd_traveller_name: pTravellerName,
                prd_id_type: pIdType,
                prd_id_number: pIdNumber,
                prd_usd_rate: usdRate == "" ? 0 : usdRate.toFixed(2),
                prd_usd_amount: usdAmt.toFixed(2),
                prd_agent_name: agentname,
                isCommission: isCommission,
                delny: "Y"
            }
            var productlist = Common.arrayAddItem(pProductList, obj);
            if (productlist.length > 0) {
                setDisableOtherCharge(false);
            }
            const obj2 = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                // TLine: productLineNo + 1,
                TLoc: pbLocationValue,
                TType: 'PB',
                // TNum?
                Product: pProduct,
                ISD: isd[2],
                Deno: pDenomination,
                Quantity: pQuantity,
                Rate: pRate,
                Amount: pRate * pQuantity * pDenomination,
                // ...(pProduct === "ETC" ? {
                ProductFrom: prFrom,
                ProductTo: prTo,
                ProductPrefix: prPrefix,
                ProductGroup: issgrp,
                ProductAccout: issaccnt,
                // } : {}),
                CommsnType: commType,
                AgentGroupCode: agentgrp,
                AgentLedgerCode: agentaccnt,
                CommsnRate: commValue == "" ? 0 : commValue,
                CommsnAmount: commission == "" ? 0 : commission,

            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
            }
            setPProductList(productlist);
            setCounter(counter + 1);
            setProductLineNo(productLineNo + 1);
            setPIsd(null);
            setPIssuer(null);
            setPDenomination("");
            setPQuantity("");
            setPRate("");
            setPrPrefix("");
            setPrFrom("");
            setPrTo("");
            setPVtmCardNo("");
            setCommValue("");
            setCommission("");
            setCommType();
            setPAgent(null);
            setCommType("");
            calcInvoice(productlist, obj);
        }
    }

    const calcInvoice = (productlist, obj) => {
        Common.callApi(Common.apiCalcTaxable, [sid, "calcinvoice", JSON.stringify(productlist), JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPForexTotal(resp.fxamt);
            setPSgst(resp.sgst);
            setPTotalAmount(resp.totalamt);
            setPTaxableVal(resp.taxablevalue);
            setPRoundOff(resp.roundoff);
            setPCgst(resp.cgst);
            setPIgst(resp.igst);
            setPUgst(resp.ugst);
            var amt = 0;
            for (var i = 0; i < paymentData.length; i++) {
                amt += paymentData[i].tp_amount * 1;
            }
            setPdAmount(1 * resp.totalamt - amt);
        });
    }

    const saveData = () => {
        $(".loader").show();
        var msg = [], i = 0;
        var totalinr = 0;
        for (var k = 0; k < paymentData.length; k++) {
            if (paymentData[k].tp_payment_type === "RC")
                totalinr -= paymentData[k].tp_amount * 1;
            else totalinr += paymentData[k].tp_amount * 1;
        }
        if (nDealer === null || (pTotalAmount > 0 && nState.value == 0)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (nDealer === null ? "Please Select Dealer." : '');
            msg[i++] = ((pTotalAmount > 0 && nState.value == 0) ? "Place of Supply." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pTotalAmount != totalinr) {
            $(".loader").hide();
            setMsgText("Total amount of payment must be equal to calculated INR.");
            setMsgBox(true);
        } else {
            const obj = {
                srno: hdrSrno,
                ppLocationValue: pbLocationValue,
                //nCorporate: nCorporate,
                nDealer: nDealer,
                //nCountry: nCountry.value,
                nState: nState.value,
                //nResident: nResident,
                nEmployeeCode: nEmployeeCode,
                pbNewDate: Common.dateYMD(pbNewDate),
                pForexTotal: pForexTotal,
                pSgst: pSgst,
                pIgst: pIgst,
                pUgst: pUgst,
                pCgst: pCgst,
                pOtherCharges: pOtherCharges === "" ? 0 : pOtherCharges,
                pRoundOff: pRoundOff,
                pTaxableVal: pTaxableVal === "" ? 0 : pTaxableVal,
                pTotalAmount: pTotalAmount,
                randomString: randomString,
                pProductList: pProductList,
                paymentData: paymentData
            }
            Common.callApi(Common.apiTranPB, [sid, "savedata", JSON.stringify(obj), JSON.stringify(tranAuditTrail)], result => {
                let resp = JSON.parse(result);
                if (resp.msg === "MSG0000") {
                    $(".loader").hide();
                    setScrMode("");
                    setMsgBox(true);
                    setMsgText(Common.getMessage(resp.msg));
                    listPBdata();
                    setTranAuditTrail([]);
                } else {
                    $(".loader").hide();
                    setMsgBox(true);
                    setMsgText(Common.getMessage(resp.msg));
                }
            });

            if (scrMode == 'E') {
                const obj = {
                    srno: hdrSrno,
                    type: 'PB',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'TRAN_PB'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })

            }
        }
    }

    const deleteProduct = (value) => {
        const itemToDelete = pProductList.find(item => item.uniquekey === value);

        if (itemToDelete) {
            const formattedItem = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                TLoc: pbLocationValue,
                TType: 'PB',
                Product: itemToDelete.prd_product,
                ISD: itemToDelete.isd_name,
                Deno: itemToDelete.prd_deno,
                Quantity: itemToDelete.prd_qty,
                Rate: itemToDelete.prd_rate,
                Amount: itemToDelete.prd_amount,
                ProductFrom: itemToDelete.prd_from,
                ProductTo: itemToDelete.prd_to,
                ProductPrefix: itemToDelete.prd_prefix,
                ProductGroup: itemToDelete.prd_group,
                ProductAccout: itemToDelete.prd_account,
                AgentGroupCode: itemToDelete.prd_agent_group,
                AgentLedgerCode: itemToDelete.prd_agent_ledger,
                CommsnType: itemToDelete.prd_commission_type,
                CommsnRate: itemToDelete.prd_commission_value,
                CommsnAmnt: itemToDelete.prd_commission,
            }
            console.log(formattedItem);
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }
        }
        const obj = {
            tranType: "PB",
            type: "PURCHASE",
            uniquekey: Common.getRandomString(15),
            nState: nState.value,
            pOtherCharges: pOtherCharges === "" ? 0 : pOtherCharges,
            ppStateType: pbStateType,
            ppStateValue: pbStateValue
        }
        const productlist = Common.arrayRemoveItem(pProductList, "uniquekey", value);
        setPProductList(productlist);
        calcInvoice(productlist, obj);
        setCounter(counter - 1);
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN(Common.getMessage("WRN0000"));
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    // const editData = () => {
    //     setScrMode("E");
    //     if (pProductList.length > 0) {
    //         setDisableOtherCharge(false);
    //     }
    // }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: hdrSrno,
            type: 'PB',
            pageright: 'TRAN_PB'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');
                if (pProductList.length > 0) {
                    setDisableOtherCharge(false);
                }

            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'PB', trannumber: hdrSrno, location: pbLocationValue, tranDate: Common.dateYMD(currDate) });
            }
        })


    }

    const fillViewForm = (hdrsrno, location, type, date) => {
        setHdrSrno(hdrsrno);
        setKey('INFO');
        setRandomString("0");
        setViewDate(date);
        $(".loader").show();
        const obj = {
            srno: hdrsrno,
            ppLocationValue: location,
            type: type
        }
        Common.callApi(Common.apiTranPB, [sid, "viewdata", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                $(".loader").hide();
                setScrMode("Q");
                //setNServiceType(resp.service_type);
                //setNResident(resp.residential);
                //setNCorporate({ id: resp.corp_id, label: resp.corp_label });
                setNDealer({ id: resp.dealer_id, label: resp.dealer_label });
                setNEmployeeCode(resp.emp_code);
                setNState({ value: resp.state, label: resp.statename });

                //setNDocument(resp.doc);
                setPForexTotal(resp.forextotal);
                setPCgst(resp.cgst);
                setPSgst(resp.sgst);
                setPIgst(resp.igst);
                setPUgst(resp.ugst);
                setPRoundOff(resp.round_off);
                setPTotalAmount(resp.total_inr);
                setPOtherCharges(resp.other_charge);
                setPTaxableVal(resp.taxablevalue);

                setPProductList(resp.productlist);
                setPaymentData(resp.paydetails);
                setProductLineNo(resp.maxlineno * 1);

                setHdrDate(resp.date);
                setHdrLocation(resp.locname);
                setHdrSrno(resp.srno);
            }
        });
    }

    const handleAccountValue = (value) => {
        setPdAccount(value);
        var id = value.id.split("^");
        setPdGroupValue(id[1]);
        setPdAccountValue(id[2]);
    }

    const handlePaymentType = (v) => {
        setDisablePaymentAccount(false);
        setPdPaymentType(v);
        setPdAccount(null);
        setPdAccountValue(0);
        setPdChequeNum("");
        var ldgType = "";
        if (v === "") return;
        if (v === "CH") {
            ldgType = "CASH";
        } else if (v === "BN" || v === "CQ") {
            ldgType = "BANK";
            setLdgSrchType("BANK");
            return;
        } else if (v === "PB" || v === "RC") {
            ldgType = "WALKIN";
            setPdAccount(nDealer);
            setDisablePaymentAccount(true);
            return;
        } else {// Advance
            setPdAccount(nDealer);
            return;
        }
        setLdgSrchType(ldgType);
        Common.callApi(Common.apiLedger, [sid, "getledgerinfo", ldgType], result => {
            let resp = JSON.parse(result);
            setPdAccount({ id: resp.id, label: resp.label });
        });
    }

    const addPayment = () => {
        var msg = [], i = 0;
        if (pTotalAmount < 1) {
            msg[i++] = (pTotalAmount < 1 ? "Total amount must be greater than 0." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pdPaymentType === "" || pdAccount === null || pdAmount < 1 || (pdPaymentType === 'CQ' && chequeValue === null) || (pdPaymentType === "BN" && pdChequeNum === "")) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pdAccount === null ? "Account." : '');
            msg[i++] = (pdPaymentType === "" ? "Payment Type." : '');
            msg[i++] = (pdAmount < 1 ? "Entered amount can't be 0." : '');
            if (pdPaymentType === 'CQ' && chequeValue === null) msg[i++] = (chequeValue === null ? "Select a Cheque." : '');
            if (pdPaymentType === "BN" && pdChequeNum === "") msg[i++] = (chequeValue === null ? "Refernece Number." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            var grp = 0;
            var accnt = 0;
            var accntname = '';
            if (pdAccount !== null) {
                var a = pdAccount.id.split("^");
                grp = a[1];
                accnt = a[2];
                accntname = a[5];
            }
            const obj = {
                uniquekey: Common.getRandomString(10),
                tp_line_no: paymentLineNo + 1,
                tp_location: pbLocationValue,
                tp_srno: hdrSrno,
                randomString: randomString,
                tp_payment_type: pdPaymentType,
                tp_account: accnt,
                tp_group: grp,
                mvli_ledger_name: accntname,
                tp_amount: pdAmount,
                tp_cheque: pdChequeNum,
                tp_type: "PB",
                chequeValue: chequeValue,
                delny: 'Y'
            }
            const paymentdata = Common.arrayAddItem(paymentData, obj);
            setPaymentData(paymentdata);
            const obj2 = {
                TabType: "Payment",
                AType: "A",
                ALineNo: tranAuditTrail.length + 1,
                // TLine: paymentLineNo + 1,
                TLoc: pbLocationValue,
                TType: 'PB',
                PaymentType: pdPaymentType,
                PaymentLedgerCode: accnt,
                PaymentGroupCode: grp,
                PaymentAccountName: accntname,
                PaymentAmount: pdAmount,
                // ...(pdPaymentType == "BN" ? {
                ReferenceNo: pdChequeNum
                // } : {}),

            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
            }
            setPaymentData(Common.arrayAddItem(paymentData, obj));
            setPdPaymentType("");
            setPaymentLineNo(paymentLineNo + 1);
            setPdAmount("");
            setPdAccount(null);
            setChequeValue(null);
            setPdChequeNum("");
            const amt = paymentdata.reduce((total, payment) => total + payment.tp_amount * 1, 0);
            setPdAmount(1 * pTotalAmount - amt);
            setCounter(counter + 1);
        }
    }

    const deletePayment = (value, delny) => {
        if (delny == "N") {
            setMsgBox(true);
            setMsgText("This cheque has already used.");
        } else {
            const itemToDelete = paymentData.find(item => item.uniquekey === value);

            if (itemToDelete) {
                const formattedItem = {
                    TabType: "Payment",
                    ALineNo: tranAuditTrail.length + 1,
                    AType: "D",
                    TLine: paymentLineNo + 1,
                    TLoc: pbLocationValue,
                    TType: 'PB',
                    PaymentType: itemToDelete.tp_payment_type,
                    PaymentGroupCode: itemToDelete.tp_group,
                    PaymentLedgerCode: itemToDelete.tp_account,
                    PaymentAccountName: itemToDelete.mvli_ledger_name,
                    PaymentAmount: itemToDelete.tp_amount,
                    ReferenceNo: itemToDelete.tp_cheque,
                };
                if (userRight.edit === "1" && scrMode === 'E') {
                    setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
                }

            }
            setPaymentData(Common.arrayRemoveItem(paymentData, "uniquekey", value));
            const amt = paymentData.reduce((total, payment) => total + payment.tp_amount * 1, 0);
            setPdAmount(1 * pTotalAmount - amt);
        }
        setCounter(counter - 1);
    }

    const print = (printflag) => {
        const obj = {
            srno: hdrSrno,
            location: pbLocationValue,
            type: 'PB',
            printFlag: printflag,
        }
        Common.callApi(Common.apiPrint, [sid, "print", JSON.stringify(obj)], result => {
            var newWindow = window.open();
            newWindow.document.write(result);
        });
    }


    const handleChequeValue = (value) => {
        setChequeValue(value);
        var id = value.id.split("^");
        setPdChequeNum(id[4]);
    }

    const handleFaView = () => {
        setShowFaView(true);
        setFaViewObject({ location: pbLocationValue, trannumber: hdrSrno, type: 'PB' });
    }

    const switchToPaymentTab = (e) => {
        if (e.key === 'Enter') setKey("PAYMENT");
        else if (e.key === "Tab") {
            e.preventDefault();
            productRef.current.focus();
        }
    }
    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "PB", trannumber: hdrSrno, trancategory: "T", location: pbLocationValue });
    }

    const handleShowCommissionDetail = () => {
        setShowCommissionDetail(true);
    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Purchase Bulk</h2>
                    </Col>
                </Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={pbLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleLocationChange}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col style={{ display: "flex" }}>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                                <Form.Check checked={radioType === "I"} onChange={() => setRadioType("I")} type='radio' label="Dealer" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === "N" ?
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Date</Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={pbNewDate}
                                                    onChange={(date) => setPBNewDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        : radioType === "S" ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control type='text' maxLength={5} value={pbSrnoFrom} onChange={e => Common.validateNumValue(e.target.value, setPBSrnoFrom)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control type='text' maxLength={5} value={pbSrnoTo} onChange={e => Common.validateNumValue(e.target.value, setPBSrnoTo)} />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : radioType === "D" || radioType === "I" || radioType === "C" ?
                                                <>
                                                    {
                                                        radioType === "I" ?
                                                            <Col className="col-md-6 col-12">
                                                                <Form.Group>
                                                                    <Form.Label>Dealer<span className="colorThemeRed">*</span></Form.Label>
                                                                    <AsyncSelect
                                                                        cacheOptions={false}
                                                                        defaultOptions={false}
                                                                        value={pbDealer}
                                                                        getOptionLabel={e => e.label + ' '}
                                                                        getOptionValue={e => e.id}
                                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'DEALER', pbLocationValue)}
                                                                        onInputChange={Picklist.handleInputChange}
                                                                        onChange={handleDealerChange}
                                                                    >
                                                                    </AsyncSelect>
                                                                </Form.Group>
                                                            </Col>
                                                            : null
                                                    }
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={pbFromDate}
                                                                onChange={(date) => setPBFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={pbToDate}
                                                                onChange={(date) => setPBToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                : null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.query === "1" && radioType !== "N" ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => listPBdata()}>List</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.add === "1" && radioType === "N" ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNewForm()}>Add New</Button> : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Table responsive striped>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Sr No</th>
                                            <th>Type</th>
                                            <th>Location</th>
                                            <th>Dealer</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pbList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span onClick={() => fillViewForm(item.hdr_srno, item.hdr_location, item.hdr_type, item.new_date)} className='pointer colorThemeBlue'>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </span>
                                                    </td>
                                                    <td>{item.hdr_srno}</td>
                                                    <td>{item.hdr_type}</td>
                                                    <td>{item.entity_name}</td>
                                                    <td>{item.mvli_ledger_name}</td>
                                                    <td>{item.hdr_date}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </>
                        :
                        <>
                            <Row style={{ display: "none" }}>
                                <Col>
                                    <p>{randomString}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => cancelConfirm()}>Back</Button>
                                    &nbsp;
                                    {
                                        (userRight.edit === "1" && scrMode === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                btnEditRequest()}>Edit</Button>&nbsp;
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    {
                                        (userRight.query === "1" && scrMode === "Q") ? <>
                                            <Button variant='outline-success' size='sm' className='buttonStyle' onClick={() => print("D")}>With Deno</Button>&nbsp;
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => print("")}>Without Deno</Button>&nbsp;
                                            <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => handleFaView()}>Fa View</Button>
                                            {(userRight.edit === "1" && scrMode === 'Q') ? <>
                                                <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                    btnActivityLog()}>Activity Log</Button>&nbsp;
                                            </> : null
                                            }
                                        </> : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Serial Number : {hdrSrno}</Form.Label>
                                </Col>
                                <Col className="col-md-2 col-4">
                                    <span>Location : <b>{hdrLocation} </b></span>
                                </Col>
                                <Col className="col-md-2 col-4">
                                    <span>Date : <b>{hdrDate}</b></span>
                                </Col>
                                <Col className='ppCharges'>
                                    <span><b>Forex Total (INR): {pForexTotal}</b></span>
                                    &nbsp;&nbsp;&nbsp;
                                    <span><b>Total Amount (INR): {pTotalAmount}</b></span>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Tabs activeKey={key} defaultActiveKey="INFO" onSelect={k => handleTabChange(k)}>
                                <Tab eventKey="INFO" title="Info">
                                    <Row>
                                        <Col className="col-md-9 col-12">
                                            <Form.Group>
                                                <Form.Label>Dealer<span className="colorThemeRed">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nDealer}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'DEALER', pbLocationValue)}
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleNewDealerChange}
                                                    isDisabled={scrMode === "Q"}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Mode</Form.Label>
                                                <Form.Select disabled={scrMode === "Q"} value={nMode} onChange={e => setNMode(e.target.value)}>
                                                    <option value="B">In Branch</option>
                                                    <option value="D">Delivery</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        {/* <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Country Visited</Form.Label>
                                                <Select isDisabled={scrMode === "Q"} options={countryOptions} value={nCountry} onChange={v => handleNewCountry(v)} />
                                            </Form.Group>
                                        </Col> 
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Residential Status<span className="colorThemeRed">*</span></Form.Label>
                                                <Form.Select disabled={scrMode === "Q"} value={nResident} onChange={e => setNResident(e.target.value)}>
                                                    {
                                                        residentOptions.map((item, index) => (
                                                            <option value={item.mrs_srno} key={index}>{item.mrs_resident}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        
                                        <Col className="col-md-3 col-6">
                                            <Form.Group>
                                                <Form.Label>Corporate</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nCorporate}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'CORPORATE', ppLocationValue)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleCorporateChange}
                                                    isDisabled={scrMode === "Q"}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>PAN/Form 60</Form.Label>
                                                <Form.Select disabled={scrMode === "Q"} value={nDocument} onChange={e => setNDocument(e.target.value)}>
                                                    <option value="">Select</option>
                                                    <option value="F">Form 60</option>
                                                    <option value="P">PAN</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        */}
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Employee Code</Form.Label>
                                                <Form.Control disabled={scrMode === "Q"} placeholder='Employee Code' type='text' value={nEmployeeCode} onChange={e => Common.validateAlpNumValue(e.target.value, setNEmployeeCode)} maxLength={20} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Place Of Supply</Form.Label>
                                                <Select isDisabled={true} options={stateOptions} value={nState} onChange={v => setNState(v)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("DETAILS")}>Details-&gt;</Button>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="DETAILS" title="Details">
                                    <Row>
                                        <Col>
                                            <h4>Product Info</h4>
                                        </Col>
                                    </Row>
                                    {
                                        scrMode === "Q" ? null
                                            :
                                            <>
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Product<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Select value={pProduct} onChange={e => handleProductChange(e.target.value)} ref={productRef}>
                                                                <option value="CN">CN</option>
                                                                {/* <option value="TC">TC</option>
                                                                <option value="VTM">VTM</option> */}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    < Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={pIsd}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, pProduct)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleChangeIsd}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        pProduct === "TC" || pProduct === "VTM" ?
                                                            <Col className="col-md-3 col-6">
                                                                <Form.Group>
                                                                    <Form.Label>Issuer<span className="colorThemeRed">*</span></Form.Label>
                                                                    <AsyncSelect
                                                                        cacheOptions={false}
                                                                        defaultOptions={false}
                                                                        value={pIssuer}
                                                                        getOptionLabel={e => e.label + ' '}
                                                                        getOptionValue={e => e.id}
                                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', pbLocationValue)} // location = 1 , 2
                                                                        onInputChange={Picklist.handleInputChange}
                                                                        onChange={value => setPIssuer(value)}
                                                                    >
                                                                    </AsyncSelect>
                                                                </Form.Group>
                                                            </Col>
                                                            : null
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>{pProduct === "VTM" ? "Value" : "Denomination"}
                                                                <span className="colorThemeRed">*</span>
                                                            </Form.Label>
                                                            <Form.Control disabled={pProduct === "VTM"} type='text' value={pDenomination} maxLength={5}
                                                                onChange={e => handleDenoChange(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col style={{ display: pProduct === "VTM" && "none" }} className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Quantity<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control type='text' placeholder='Quantity' value={pQuantity} maxLength={9}
                                                                onChange={e => handleQuantityChange(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Rate<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control type='text' placeholder='Rate' value={pRate} maxLength={5}
                                                                onChange={e => handleRateChange(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {
                                                    pProduct === "TC" || pProduct === "VTM" ?
                                                        <Row>
                                                            <Col style={{ display: pProduct === "VTM" && "none" }} className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Prefix</Form.Label>
                                                                    <Form.Control placeholder='Prefix' type='text' maxLength={5} value={prPrefix} onChange={e => Common.validateAlpNumValue(e.target.value, setPrPrefix)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control disabled={pProduct === "VTM"} placeholder='From' type='text' maxLength={6} value={prFrom} onChange={e => handleFromChange(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                            {
                                                                pProduct === "TC" ?
                                                                    <Col className='col-md-3 col-6'>
                                                                        <Form.Group>
                                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                                            <Form.Control disabled placeholder='To' type='text' maxLength={6} value={prTo} onChange={e => Common.validateNumValue(e.target.value, setPrTo)} />
                                                                        </Form.Group>
                                                                    </Col> :
                                                                    <Col className='col-md-3 col-6'>
                                                                        <Form.Group>
                                                                            <Form.Label>Card No</Form.Label>
                                                                            <Form.Control disabled placeholder='Card No' type='text' value={pVtmCardNo} onChange={e => setPVtmCardNo(e.target.value)} />
                                                                        </Form.Group>
                                                                    </Col>
                                                            }
                                                        </Row>
                                                        : null
                                                }
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Traveller Name</Form.Label>
                                                            <Form.Control placeholder='Traveller Name' type='text' maxLength={50} value={pTravellerName} onChange={e => Common.validateAlpValue(e.target.value, setPTravellerName)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>ID type</Form.Label>
                                                            <Form.Select value={pIdType} onChange={e => setPIdType(e.target.value)}>
                                                                <option value="0">Select</option>
                                                                <option value="1">Adhaar Card</option>
                                                                <option value="2">Passport</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>ID Number</Form.Label>
                                                            <Form.Control placeholder='ID Number' type='text' maxLength={30} value={pIdNumber} onChange={e => Common.validateAlpNumValue(e.target.value, setPIdNumber)} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Commission</Form.Label>
                                                            <Form.Select value={isCommission} onChange={e => setIsCommission(e.target.value)}>
                                                                <option value="0">No</option>
                                                                <option value="1">Yes</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        isCommission === "1" ?
                                                            <>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Agent<span className="colorThemeRed">*</span></Form.Label>
                                                                        <AsyncSelect
                                                                            cacheOptions={false}
                                                                            defaultOptions={false}
                                                                            value={pAgent}
                                                                            getOptionLabel={e => e.label + ' '}
                                                                            getOptionValue={e => e.id}
                                                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'AGENT', pbLocationValue)} // location = 1 , 2
                                                                            onInputChange={Picklist.handleInputChange}
                                                                            onChange={handleAgentChange}
                                                                        >
                                                                        </AsyncSelect>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className="col-md-3 col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Select value={commType} onChange={e => commissionTypeChange(e.target.value)}>
                                                                            <option value="">Select</option>
                                                                            <option value="P">Paisa</option>
                                                                            <option value="R">Percentage</option>
                                                                            <option value="F">Flat</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label>Commission<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Control type='text' maxLength={5} value={commission} onChange={e => handleCommissionCalc(e.target.value)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label>Commission Value</Form.Label>
                                                                        <Form.Control disabled value={commValue} onChange={e => Common.validateDecValue(e.target.value, setCommValue)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            : null
                                                    }
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addProduct()}>Add</Button>
                                                        &nbsp;
                                                        <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("PAYMENT")} onKeyDown={switchToPaymentTab}>Payment-&gt;</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                    <Row>
                                        <Col className="col-md-3 col-6">
                                            <Badge className='handCursor' bg="primary" onClick={handleShowCommissionDetail}>View Commission Details</Badge>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Table responsive bordered striped>
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Isd Code</th>
                                                    <th>Denomination</th>
                                                    <th>Quantity</th>
                                                    <th>Rate</th>
                                                    <th>Amount</th>
                                                    <th>USD Rate</th>
                                                    <th>USD Amount</th>
                                                    <th>Prefix</th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Traveller Name</th>
                                                    <th>ID Type</th>
                                                    <th>ID Number</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    pProductList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.prd_product}</td>
                                                            <td>{item.prd_isd}</td>
                                                            <td>{item.prd_deno}</td>
                                                            <td>{item.prd_qty}</td>
                                                            <td>{item.prd_rate}</td>
                                                            <td>{item.prd_amount}</td>
                                                            <td>{item.prd_usd_rate}</td>
                                                            <td>{item.prd_usd_amount}</td>
                                                            <td>{item.prd_prefix}</td>
                                                            <td>{item.prd_from}</td>
                                                            <td>{item.prd_to}</td>
                                                            <td>{item.prd_traveller_name}</td>
                                                            <td>{item.prd_id_type == 0 ? "" : item.prd_id_type}</td>
                                                            <td>{item.prd_id_number}</td>
                                                            <td>
                                                                {
                                                                    scrMode === "Q" || item.delny === "N" ? null :
                                                                        <span onClick={() => deleteProduct(item.uniquekey)} className='colorThemeBlue pointer'>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>
                                <Tab eventKey="PAYMENT" title="Payment">
                                    {/* <Row>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>Taxable Value: {pTaxableVal}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>SGST: {pSgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>IGST: {pIgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>CGST : {pCgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>UGST : {pUgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>Round Off: {pRoundOff}</Form.Label>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col className="col-md-3 col-6" style={{ display: "flex", alignItems: "center" }}>
                                            <span>OtherCharges: &nbsp;</span>
                                            <span>
                                                <Form.Control size='sm' placeholder='Other Charges' type='text'
                                                    maxLength={5}
                                                    value={pOtherCharges}
                                                    onChange={e => otherChargeChange(e.target.value)}
                                                    disabled={disableOtherCharge || scrMode === "Q"}
                                                />
                                            </span>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col><h3>Payment Details</h3></Col>
                                    </Row>
                                    {
                                        scrMode === "Q" ? null
                                            :
                                            <>
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Payment Type</Form.Label>
                                                            <Form.Select value={pdPaymentType} onChange={e => handlePaymentType(e.target.value)}>
                                                                <option value="">Select</option>
                                                                <option value="BN">Bank</option>
                                                                <option value="CQ">Cheque</option>
                                                                <option value="RC">Recievables</option>
                                                                <option value="PB">Payables</option>
                                                                <option value="AP">Advanced</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Account<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={pdAccount}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, ldgSrchType, pbLocationValue)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleAccountValue}
                                                                isDisabled={disablePaymentAccount}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        pdPaymentType !== "" ?
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Amount</Form.Label>
                                                                    <Form.Control placeholder='Amount' value={pdAmount} onChange={e => setPdAmount(e.target.value)} />
                                                                </Form.Group>
                                                            </Col> : null
                                                    }
                                                </Row>
                                                {
                                                    pdPaymentType === "BN" ?
                                                        <Row>
                                                            <Col className="col-md-3 col-6">
                                                                <Form.Group>
                                                                    <Form.Label>Reference No</Form.Label>
                                                                    <Form.Control placeholder='Reference No' maxLength={40} value={pdChequeNum} onChange={e => Common.validateAlpValue(e.target.value, setPdChequeNum)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        : pdPaymentType === "CQ" ?
                                                            <Row>
                                                                <Col className="col-md-3 col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>Cheque No</Form.Label>
                                                                        <AsyncSelect
                                                                            cacheOptions={false}
                                                                            defaultOptions={false}
                                                                            value={chequeValue}
                                                                            getOptionLabel={e => e.label + ' '}
                                                                            getOptionValue={e => e.id}
                                                                            loadOptions={(search) => Picklist.fetchChequeBookBankPicklist(search, pdGroupValue, pdAccountValue, pbLocationValue)}
                                                                            onInputChange={Picklist.handleInputChange}
                                                                            onChange={handleChequeValue}
                                                                        >
                                                                        </AsyncSelect>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            :
                                                            null
                                                }
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => addPayment()}>Add</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Table responsive striped bordered>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Group</th>
                                                    <th>Account</th>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    <th>Cheque/Ref no</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    paymentData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {
                                                                    item.tp_payment_type === "BN"
                                                                        ? "Bank" : item.tp_payment_type === "CQ"
                                                                            ? "Cheque" : item.tp_payment_type === "CH"
                                                                                ? "Cash" : item.tp_payment_type === "RC"
                                                                                    ? "Reveivables" : item.tp_payment_type === "PB"
                                                                                        ? "Payables" : item.tp_payment_type === "AP"
                                                                                            ? "Advance Paid" : item.tp_payment_type === "AR"
                                                                                                ? "Advance Received" : ""
                                                                }
                                                            </td>
                                                            <td>{item.tp_group}</td>
                                                            <td>{item.tp_account}</td>
                                                            <td>{item.mvli_ledger_name}</td>
                                                            <td>{item.tp_amount}</td>
                                                            <td>{item.tp_cheque}</td>
                                                            <td>
                                                                {
                                                                    scrMode !== "Q" ?
                                                                        <span className='colorThemeBlue pointer' onClick={() => deletePayment(item.uniquekey, item.delny)}>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span> : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>
                            </Tabs>
                            <hr />
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </>
                }
            </Container >

            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            <FaView faViewObject={faViewObject} show={showFaView} onHide={() => setShowFaView(false)} />

            <Tran_commission_view show={showCommissionDetail} onHide={() => setShowCommissionDetail(false)} productList={pProductList} />

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
            <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />
        </>
    )
}

export default Tran_pb
