import React, { useState, useEffect } from 'react'
import Login_header from '../../Login_header';
import LocationSelector from '../../LocationSelector';
import Menu from '../../Menu';
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Common from '../../Common';
import * as Picklist from '../../Picklist';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import AsyncSelect from 'react-select/async';
import Dialog from '../../Dialog';
import DialogYesNo from '../../Dialog_Yes_No';

const Tran_conveyance_entry = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState('');
    const [scrMode, setScrMode] = useState('');

    const [conveyanceData, setConveyanceData] = useState([]);
    const [conveyance, setConveyance] = useState([]);
    const [uploadedDocs, setUploadedDocs] = useState([]);
    const [conveyanceFilter, setConveyanceFilter] = useState("L");
    const [conveyanceUser, setConveyanceUser] = useState('');
    const [conveyanceFromDate, setConveyanceFromDate] = useState(new Date());
    const [conveyanceToDate, setConveyanceToDate] = useState(new Date());
    const [conveyanceStatus, setConveyanceStatus] = useState("A");


    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');

    const [allChecked, setAllChecked] = useState(false);

    const [documentToView, setDocumentToView] = useState("");
    const [documentTypeToView, setDocumentTypeToView] = useState("");
    const [documentDescToView, setDocumentDescToView] = useState("");
    const [documentNameToView, setDocumentNameToView] = useState("");
    const [showDocument, setShowDocument] = useState(false);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_CONVEYANCE'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }

    const handleFilterList = () => {
        const obj = {
            conveyanceFilter: conveyanceFilter,
            radioType: radioType,
            location: locationCode,
            multipleLocation: selectedItems.join(),
            user: conveyanceUser,
            fromDate: Common.dateYMD(conveyanceFromDate),
            toDate: Common.dateYMD(conveyanceToDate),
            conveyanceStatus: conveyanceStatus,
        }
        Common.callApi(Common.apiConveyanceEntry, [sid, 'coveyanceList', JSON.stringify(obj)], (result) => {
            setConveyanceData(JSON.parse(result));
        });
    }

    const openEditForm = (srno, status, randomNumber) => {
        setScrMode('E');
        const obj = {
            srno: srno,
            status: status,
            randomNumber: randomNumber,
        }
        Common.callApi(Common.apiConveyanceEntry, [sid, 'edit', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setConveyance(resp.convArray);
            setUploadedDocs(resp.docArray);
        });
    }

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        // console.log(isChecked);
        setAllChecked(isChecked);

        const updatedConveyanceData = conveyanceData.map(item => ({
            ...item,
            ischecked: isChecked ? '1' : '0'
        }));
        // console.log("Update Conveyance Data is :", updatedConveyanceData);
        setConveyanceData(updatedConveyanceData);
    };

    const updateCheck = (index, e, name) => {
        const isChecked = e.target.checked;
        // console.log(isChecked);

        const updatedConveyanceData = conveyanceData.map((item, i) => {
            if (index === i) {
                return { ...item, [name]: isChecked ? '1' : '0' };
            }
            return item;
        });
        // console.log("Updated Conveyance Data:", updatedConveyanceData);
        setConveyanceData(updatedConveyanceData);
    };

    const handleBack = () => {
        setScrMode('');
    }

    const handleViewDoc = (srno, lineNo, randomNumber, docExt) => {
        const obj = {
            srno: srno,
            lineNo: lineNo,
            randomNumber: randomNumber,
            docExt: docExt,
            name: 'conveyanceDoc',
            pageright: "TRAN_CONVEYANCE",
        }
        Common.callDocumentViewApi(Common.apiDocument, [sid, 'docView', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setDocumentToView(resp.bs64);
            setDocumentTypeToView(resp.typ);
            setDocumentDescToView(resp.desc);
            setDocumentNameToView(resp.fname);
            setShowDocument(true);
        });
    }

    const btnDocumentDownload = (srno, lineNo, randomNumber) => {
        const obj = {
            srno: srno,
            lineNo: lineNo,
            randomNumber: randomNumber,
            name: 'conveyanceDoc',
            pageright: "TRAN_CONVEYANCE",
        }
        Common.callDownloadApiPost(Common.apiDocument, "post", [sid, 'docdownload', JSON.stringify(obj)]);
    }

    const handleSendForTransfer = () => {
        console.log("Send for Transfer Data.");
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Conveyance Entry</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Form.Label>Filter<span className="colorThemeRed">*</span></Form.Label>
                                        <Form.Select value={conveyanceFilter} onChange={(e) => setConveyanceFilter(e.target.value)} required>
                                            <option value="L">Location</option>
                                            <option value="U">User Name</option>
                                            <option value="D">Date</option>
                                            <option value="S">Status</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {
                                    conveyanceFilter == "L" ?
                                        <>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Label>Location Filter</Form.Label><br />
                                                <LocationSelector onDataFromChild={handleDataFromChild} />
                                            </Col>
                                        </>
                                        : conveyanceFilter == "U" ?
                                            <>
                                                <Col className='col-md-4 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>User Name<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="User Name"
                                                            value={conveyanceUser}
                                                            onChange={(e) => setConveyanceUser(e.target.value)}
                                                            autoComplete="off" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : conveyanceFilter === "D" ?
                                                <>
                                                    <Col className='col-md-4 col-6'>
                                                        <Form.Group>
                                                            <Form.Label> From Date</Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={conveyanceFromDate}
                                                                onChange={(date) => setConveyanceFromDate(date)}
                                                                maxDate={new Date()}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-4 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To Date</Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={conveyanceToDate}
                                                                onChange={(date) => setConveyanceToDate(date)}
                                                                maxDate={new Date()}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                :
                                                <>
                                                    <Col className='col-md-4 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Filter<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Select value={conveyanceStatus} onChange={(e) => setConveyanceStatus(e.target.value)} required>
                                                                <option value="">Select</option>
                                                                <option value="A">Approved</option>
                                                                <option value="P">Pending</option>
                                                                <option value="R">Rejected</option>
                                                                <option value="P">Paid</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => handleFilterList()}>List</Button>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered>
                                        <thead>
                                            <tr>
                                                {/* <td>&nbsp;</td> */}
                                                <td>
                                                    <Form.Check
                                                        type='checkbox'
                                                        label=""
                                                        checked={allChecked}
                                                        onChange={handleSelectAll}
                                                    />
                                                </td>
                                                <th>Srno</th>
                                                <th>Type</th>
                                                <th>Branch</th>
                                                <th>Mode</th>
                                                <th>Amount</th>
                                                <th>From Date</th>
                                                <th>To Date</th>
                                                <th>Status</th>
                                                <th>User Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                conveyanceData.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <span style={{ display: "inline-block" }}>
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    label=""
                                                                    checked={data.ischecked === '1'}
                                                                    name="ischecked"
                                                                    onChange={(e) => updateCheck(index, e, "ischecked")}
                                                                />
                                                            </span>&nbsp;
                                                            <span className="handCursor colorThemeBlue" onClick={() => openEditForm(data.tce_srno, data.tce_status, data.tce_random_number)}>
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </span>&nbsp;
                                                        </td>
                                                        <td>{data.tce_srno}</td>
                                                        <td>{
                                                            data.tce_type === "BV" ?
                                                                "Branch Visit" : data.tce_type === "D" ?
                                                                    "Delivery" : data.tce_type === "LG" ? "Lead Generation" : null
                                                        }</td>
                                                        <td>{data.entity_name}</td>
                                                        <td>{
                                                            data.tce_mode === "H" ?
                                                                "Hotel" : data.tce_mode === "F" ?
                                                                    "Food" : data.tce_mode === "B" ?
                                                                        "Bike" : data.tce_mode === "C" ?
                                                                            "Car" : data.tce_mode === "S" ?
                                                                                "Bus" : data.tce_mode === "M" ?
                                                                                    "Metro" : data.tce_mode === "A" ?
                                                                                        "Auto" : "Taxi"
                                                        }</td>
                                                        <td>{data.tce_amount}</td>
                                                        <td>{data.tce_from_date}</td>
                                                        <td>{data.tce_to_date}</td>
                                                        <td>{data.tce_status === "P" ?
                                                            "Pending" : data.tce_status === "A" ?
                                                                "Approved" : data.tce_status === "R" ?
                                                                    "Rejected" : "Paid"}</td>
                                                        <td>{data.um_user_name}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => handleSendForTransfer()}>Send for Transfer</Button>
                                </Col>
                            </Row>
                        </> :
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Srno: <b>{conveyance[0]?.tce_srno}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Type: <b>{conveyance[0]?.tce_type === "BV" ? "Branch Visit" : conveyance[0]?.tce_type === "D" ? "Manual No / Invoice No." : "Lead Generation"}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Branch: <b>{conveyance[0]?.entity_name}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Mode: <b>{conveyance[0]?.tce_mode === "F" ? "Food" : conveyance[0]?.tce_mode === "H" ? "Hotel" : conveyance[0]?.tce_mode === "B" ? "Bike" : conveyance[0]?.tce_mode === "C" ? "Car" : conveyance[0]?.tce_mode === "BS" ? "Bus" : conveyance[0]?.tce_mode === "M" ? "Metro" : conveyance[0]?.tce_mode === "A" ? "Auto" : "Taxi"}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>From Place: <b>{conveyance[0]?.tce_from}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>To Place: <b>{conveyance[0]?.tce_to}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Distance: <b>{conveyance[0]?.tce_distance}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Amount: <b>{conveyance[0]?.tce_amount}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Remark: <b>{conveyance[0]?.tce_remark}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>From Date: <b>{conveyance[0]?.tce_from_date}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>To Date: <b>{conveyance[0]?.tce_to_date}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Status: <b>{conveyance[0]?.tce_status === "P" ? "Pending" : conveyance[0]?.tce_status === "R" ? "Rejected" : conveyance[0]?.tce_status === "A" ? "Approved" : "Paid"}</b></Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <hr />
                            <Row><h5 style={{ paddingTop: "11px" }}>Uploaded Documents</h5></Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Ext</th>
                                                <th>Document Name</th>
                                                <th>Document Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                uploadedDocs.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="textCenter">
                                                            {
                                                                item.tmd_doc_ext === "jpg" || item.tmd_doc_ext === "jpeg" || item.tmd_doc_ext === "png" || item.tmd_doc_ext === "pdf" || item.tmd_doc_ext === "bmp" || item.tmd_doc_ext === "gif" ?
                                                                    <>
                                                                        <span className="pointer colorThemeBlue" onClick={() => handleViewDoc(item.tmd_srno, item.tmd_line_no, item.tmd_random_number, item.tmd_doc_ext)}>
                                                                            <FontAwesomeIcon icon={faEye} />
                                                                        </span>&nbsp;
                                                                    </> : null
                                                            }
                                                            <span className="pointer colorThemeBlue" onClick={() => btnDocumentDownload(item.tmd_srno, item.tmd_line_no, item.tmd_random_number)}>
                                                                <FontAwesomeIcon icon={faFileDownload} />
                                                            </span>
                                                        </td>
                                                        <td>{item.tmd_doc_ext}</td>
                                                        <td>{item.tmd_doc_name}</td>
                                                        <td>{item.tmd_doc_desc}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => handleBack()}>Back</Button>
                                </Col>
                            </Row>
                        </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <Modal
                show={showDocument}
                onHide={() => setShowDocument(false)}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {documentDescToView + " : " + documentNameToView}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={documentToView} type={documentTypeToView} style={{ minHeight: "100vh", minWidth: "100%" }} ></embed>
                </Modal.Body>
            </Modal>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
        </>
    )
}

export default Tran_conveyance_entry;
